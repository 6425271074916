import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import {
  getProviderCapabilitiesById,
  getIsCalendarTool,
  getLinkContainerBySide,
  getLinkProviderNameBySide,
  getSupportedFieldsForItem,
  getProviderByName,
} from 'reducers';
import * as fieldTypes from '~/consts/fields';
import { useGetItemTypes } from '~/hooks/useGetItemTypes';

import { useDeepFiltering } from './useDeepFiltering';
import { groupByField } from '../utils';
import { useGetContainerTypes } from '../../../hooks/useGetContainerTypes';
import { useWatchFieldArray } from '../../../hooks';

function useGetSide({ containerSide, linkId }) {
  const { watch } = useFormContext();
  const [itemTypeA, itemTypeB] = useGetItemTypes(linkId);
  const itemType = containerSide === 'A' ? itemTypeA : itemTypeB;
  const [containerTypeA, containerTypeB] = useGetContainerTypes(linkId);
  const containerType = containerSide === 'A' ? containerTypeA : containerTypeB;
  const providerName = useSelector((state) => getLinkProviderNameBySide(state, { containerSide, linkId }));
  const container = useSelector((state) => getLinkContainerBySide(state, { containerSide, linkId }));
  const provider = useSelector((state) => getProviderByName(state, providerName));
  const providerFields = useSelector((state) =>
    getSupportedFieldsForItem(state, provider.get('_id'), container.get('id'), itemType),
  );

  // TODO PCDv3: get rid of legacy options here when options is fully merged in pcdv3 - aug 28
  const providerCapabilitiesOptions = useSelector((state) =>
    getProviderCapabilitiesById(state, provider.get('_id'), 'options'),
  );
  const hasRequiredDate = useSelector((state) => getIsCalendarTool(state, provider.get('_id'), itemType));
  const providerIdentityId = watch(`${containerSide}.providerIdentityId`);
  const [hasDeepFiltering, defaultDeepFilterItemFieldId] = useDeepFiltering(provider.get('_id'), providerFields);
  const fieldArray = useWatchFieldArray(`${containerSide}.filters`);
  const fieldArrayDeepFilters = useWatchFieldArray(`${containerSide}.deepFilters`);
  const fieldArrayActions = useWatchFieldArray(`${containerSide}.actions`);

  return {
    ...fieldArray,
    containerType,
    itemType,
    providerFields,
    actions: fieldArrayActions,
    deepFilters: fieldArrayDeepFilters,
    groupedFields: groupByField(fieldArray.fields, providerFields),
    // using groupByField to group legacy options still, safe because the logic related to allowList and denyList doesn't
    // really apply on options, we just use the function to group them.
    groupedActionFields: groupByField(fieldArrayActions.fields, providerCapabilitiesOptions),
    groupedDeepFields: groupByField(fieldArrayDeepFilters.fields, providerFields),
    containerName: container.get('displayName'),
    containerPath: container.get('path'),
    providerName,
    providerDisplayName: provider.get('displayName'),
    containerId: container.get('id'),
    containerUrl: container.get('url'),
    providerIdentityId,
    // we can include closed tasks if the provider used by this side has a semantic status field which is not readOnly.
    canCloseTasks: providerFields.some(
      (field) => field.get('semantic') === fieldTypes.SEMANTIC.WORKFLOW_STATUS && !field.get('readOnlyOnUpdate'),
    ),
    hasSubfolders: providerCapabilitiesOptions.has(fieldTypes.SUBFOLDERS),
    hasDeepFiltering,
    defaultDeepFilterItemFieldId,
    hasRequiredDate,
  };
}

export function useGetSides(linkId) {
  const sideA = useGetSide({ containerSide: 'A', linkId });
  const sideB = useGetSide({ containerSide: 'B', linkId });

  return { A: sideA, B: sideB };
}
