import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Box, Typography, tokens } from '@unitoio/mosaic';

import { getProviderDisplayName } from 'reducers';
import { capitalize } from '~/utils/capitalize';
import { ProviderTermsByName } from '~/components/ProviderTerms/ProviderTermsByName';

export const GuideStepWorkItemLabel = ({
  providerName,
  providerIdentity,
  containerName,
  containerExtraConfigDetails,
  containerError,
  itemType,
  containerType,
}) => {
  const providerDisplayName = useSelector((state) => getProviderDisplayName(state, providerName));

  if (containerName && containerExtraConfigDetails) {
    return (
      <Box flexDirection="row">
        <Typography>{containerName} -&nbsp;</Typography>
        <Typography color={tokens.colors.content.destructive.default}>
          {containerExtraConfigDetails.get('message')}
        </Typography>
      </Box>
    );
  }
  if (containerError) {
    return containerError;
  }

  if (containerName) {
    return containerName;
  }

  if (providerIdentity && !providerIdentity.isEmpty()) {
    return (
      <>
        {capitalize(providerDisplayName)}{' '}
        <ProviderTermsByName
          providerNameA={providerName}
          termKey="container"
          pcdv3
          itemTypeA={itemType}
          containerTypeA={containerType}
        />{' '}
        is missing
      </>
    );
  }

  if (providerDisplayName) {
    return `${capitalize(providerDisplayName)} account is missing`;
  }

  return 'Tool is missing';
};

GuideStepWorkItemLabel.propTypes = {
  containerError: PropTypes.string,
  containerExtraConfigDetails: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.bool]),
  containerName: PropTypes.string,
  containerType: PropTypes.string,
  itemType: PropTypes.string,
  providerIdentity: PropTypes.instanceOf(Map),
  providerName: PropTypes.string,
};
