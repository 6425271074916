import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Route, Switch, useHistory, generatePath } from 'react-router-dom';
import styled from 'styled-components';
import { useWatch } from 'react-hook-form';
import { Map } from 'immutable';

import { Box, tokens, Grid, Row, Col, Button } from '@unitoio/mosaic';

import * as linkTypes from '~/consts/link';
import * as routes from '~/consts/routes';
import * as trackingTypes from '~/consts/tracking';
import * as featureTypes from '~/consts/features';
import { useLogger } from '~/hooks/useLogger';
import { getLinkById, getSelectedOrganizationId } from 'reducers';
import * as workflowActions from '~/actions/workflows';
import { FeatureFlag } from '~/components/FeatureFlag/FeatureFlag';
import { FeatureFlagVariant } from '~/components/FeatureFlag/FeatureFlagVariant';
import { RoutedNavTabs } from '~/components/NavTabs/RoutedNavTabs';
import { RemoveFlowModal } from '~/components/RemoveFlowModal/RemoveFlowModal';
import * as linkActions from '~/actions/links';

import { LinkItemDeleteModal } from '../../../../components/LinkItem/LinkItemDeleteModal';
import { SyncStatusFormActivityStream } from '../../../SyncStatusForm/SyncStatusFormActivityStream';
import { PageContainer } from '../PageContainer/PageContainer';
import { GuideStep } from '../../components/GuideStep/GuideStep';
import { AdvancedOptions } from '../../components/GuideStep/AdvancedOptions';
import { GuideStepFlowDirection } from '../../components/GuideStep/GuideStepFlowDirection';
import { GuideStepWorkItems } from '../../components/GuideStep/GuideStepWorkItems';
import { GuideStepRules } from '../../components/GuideStep/GuideStepRules';
import { GuideStepMapping } from '../../components/GuideStep/GuideStepMapping';
import { GuideStepReview } from '../../components/GuideStep/GuideStepReview';
import { GuideStepAddOnConfiguration } from '../../components/GuideStep/GuideStepAddOnConfiguration';
import { PageHeader } from '../../components/PageHeader';
import { loadingStates } from '../../utils/form';
import { FlowBuilderErrorFunnel } from '../../components/FlowBuilderErrorFunnel';
import { SyncStatusPageAlert } from '../../components/SyncStatusPageAlert';
import { useHasAnomaliesOnPage, PAGES } from '../../hooks/useGetAnomalies';
import { useShouldDisplayAddOnConfigurationStep } from '../../hooks/useShouldDisplayAddOnConfigurationStep';
import { DuplicateBanner } from '../../components/DuplicateBanner';
import { TurnOffTestModeBox } from '../../components/TurnOffTestModeBox';
import { GuideEditHeader } from './GuideEditHeader';
import { Sidebar } from './Sidebar/Sidebar';

const NoPaddingGrid = styled((props) => <Grid {...props} />)`
  padding: 0 !important;
  margin-bottom: ${tokens.spacing.s7};
`;

const DeleteButton = styled(Button)`
  color: ${tokens.colors.content.destructive.default};
  &:hover {
    color: ${tokens.colors.content.destructive.default};
  }
`;

const getTabRoutes = (match) => {
  const guidePath = generatePath(match.path, { ...match.params, pageName: routes.FLOW_BUILDER_PAGES.GUIDE });
  const advancedPath = `${guidePath}/advanced`;

  return [
    {
      activeOnPaths: [guidePath],
      path: guidePath,
      exact: true,
      tab: 'Overview',
    },
    {
      path: advancedPath,
      exact: true,
      tab: 'Advanced options',
    },
  ];
};

const GuideSteps = ({ isLoading, isDraftFlow, linkState }) => {
  const { linkId } = useParams();
  const linkKind = useSelector((state) => getLinkById(state, linkId)).get('kind');

  const isLiteGuide = linkTypes.GUIDE_VARIANTS[linkKind] === linkTypes.GUIDE_VARIANT_TYPE.LITE;
  const shouldDisplayAddonConfiguration = useShouldDisplayAddOnConfigurationStep(linkId);

  if (isLoading) {
    return (
      <>
        <GuideStep status="loading" />
        <GuideStep status="loading" />
        <GuideStep status="loading" />
        <GuideStep status="loading" />
        <GuideStep status="loading" />
        <GuideStep status="loading" />
      </>
    );
  }

  return (
    <>
      <FlowBuilderErrorFunnel pageName={trackingTypes.MODULE.TOOL_SELECTION}>
        <GuideStepWorkItems />
      </FlowBuilderErrorFunnel>

      {(!isLiteGuide || !isDraftFlow) && (
        <FlowBuilderErrorFunnel pageName={trackingTypes.MODULE.FLOW_DIRECTION}>
          <GuideStepFlowDirection linkState={linkState} />
        </FlowBuilderErrorFunnel>
      )}

      {!isLiteGuide && (
        <FlowBuilderErrorFunnel pageName={trackingTypes.MODULE.RULES}>
          <GuideStepRules linkState={linkState} />
        </FlowBuilderErrorFunnel>
      )}

      <FlowBuilderErrorFunnel pageName={trackingTypes.MODULE.MAPPINGS}>
        <GuideStepMapping linkState={linkState} />
      </FlowBuilderErrorFunnel>

      <FeatureFlag name={featureTypes.FEATURES.ADD_ON_IMPROVEMENTS}>
        <FeatureFlagVariant value={true}>
          {shouldDisplayAddonConfiguration && isDraftFlow && (
            <FlowBuilderErrorFunnel pageName={trackingTypes.MODULE.ADD_ON}>
              <GuideStepAddOnConfiguration />
            </FlowBuilderErrorFunnel>
          )}
        </FeatureFlagVariant>
      </FeatureFlag>

      {isDraftFlow && <GuideStepReview linkState={linkState} />}
    </>
  );
};

GuideSteps.propTypes = {
  isDraftFlow: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  linkState: PropTypes.string.isRequired,
};

function useFetchWorkflowResources({ linkId, organizationId, workflowId }) {
  const dispatch = useDispatch();
  const { reportException } = useLogger();
  const [workflowResources, setWorkflowResources] = useState({});
  const [workflowResourcesLoading, setWorkflowResourcesLoading] = useState(false);

  useEffect(() => {
    async function getResources() {
      try {
        setWorkflowResourcesLoading(true);
        const fetchWorkflowResources = await dispatch(
          workflowActions.generateWorkflowResourcesOnDelete(organizationId, [linkId]),
        );
        setWorkflowResources(fetchWorkflowResources);
      } catch (err) {
        reportException(err);
      } finally {
        setWorkflowResourcesLoading(false);
      }
    }

    if (workflowId && linkId) {
      getResources();
    }
  }, [dispatch, organizationId, linkId, workflowId, reportException]);
  return { workflowResources, workflowResourcesLoading };
}

export const Guide = ({ loadingState, isDuplicating, match }) => {
  const [showRemoveFlowModal, setShowRemoveFlowModal] = useState(false);
  const { linkId, workflowId } = useParams();
  const linkState = useWatch({ name: 'state' });
  const isDraftFlow = !linkId || linkState === linkTypes.LINK_STATES.DRAFT;
  const [isEduBannerDisplayed, setIsEduBannerDisplayed] = useState(isDraftFlow);
  const history = useHistory();
  const dispatch = useDispatch();

  const organizationId = useSelector((state) => getSelectedOrganizationId(state));
  const currentLink = useSelector((state) => getLinkById(state, linkId));
  const containerA = currentLink.getIn(['A', 'container']);
  const containerB = currentLink.getIn(['B', 'container']);
  const providerA = currentLink.getIn(['A'], Map());
  const providerB = currentLink.getIn(['B'], Map());
  const [hasSyncStatusErrors, hasSyncStatusWarnings] = useHasAnomaliesOnPage(linkId, PAGES.GUIDE);

  const onReviewRulesPageChange = () => {
    const rulesPage = generatePath(match.path, { ...match.params, pageName: routes.FLOW_BUILDER_PAGES.RULES });
    history.push(rulesPage);
  };

  // for workflow designer flow deletion
  const { workflowResources, workflowResourcesLoading } = useFetchWorkflowResources({
    workflowId,
    linkId,
    organizationId,
  });
  const workflowNames = workflowResources?.workflows?.map((workflow) => workflow.name).join(', ');
  const isDeletable = !!workflowResources?.deletableLinks?.length;

  return (
    <>
      <PageContainer sidebar={Sidebar}>
        <>
          {isDraftFlow ? (
            <>
              <PageHeader title="Welcome to Unito's quick-start walkthrough" />
              <DuplicateBanner />
              <GuideSteps
                isDraftFlow={isDraftFlow}
                isLoading={[loadingStates.LOADING, loadingStates.INITIAL].includes(loadingState) || isDuplicating}
                linkState={linkState}
                isEduBannerDisplayed={isEduBannerDisplayed}
                setIsEduBannerDisplayed={setIsEduBannerDisplayed}
              />
            </>
          ) : (
            <>
              <GuideEditHeader
                providerNameA={providerA.get('providerName')}
                providerNameB={providerB.get('providerName')}
                lastSyncRequest={currentLink.get('lastSyncRequest')}
                creatorName={currentLink.getIn(['user', 'fullName'])}
                containerA={containerA}
                containerB={containerB}
                organizationId={organizationId}
              />
              <TurnOffTestModeBox />
              <Box m={[0, 0, tokens.spacing.s6, 0]}>
                <RoutedNavTabs routes={getTabRoutes(match)} tabStyle="underline" />
              </Box>
              {(hasSyncStatusErrors || hasSyncStatusWarnings) && (
                <SyncStatusPageAlert key="sync_status_guide" page={PAGES.GUIDE} />
              )}
              <Switch>
                <Route path={`${match.path}/advanced`} render={(routeProps) => <AdvancedOptions {...routeProps} />} />
                <Route
                  render={() => (
                    <GuideSteps
                      isDraftFlow={isDraftFlow}
                      isLoading={[loadingStates.LOADING, loadingStates.INITIAL].includes(loadingState) || isDuplicating}
                      linkState={linkState}
                    />
                  )}
                />
              </Switch>
            </>
          )}
          {workflowId ? (
            <RemoveFlowModal
              isOpen={showRemoveFlowModal}
              isDeletable={isDeletable}
              linkId={linkId}
              workflowNames={workflowNames}
              onConfirm={async () => {
                if (isDeletable) {
                  await dispatch(linkActions.deleteLink(linkId, workflowId));
                } else {
                  await dispatch(workflowActions.removeFlow(workflowId, linkId));
                }
                return history.push(`${routes.ABSOLUTE_PATHS.EDIT_WORKFLOW}/${workflowId}`);
              }}
              onCancel={() => setShowRemoveFlowModal(false)}
            />
          ) : (
            <LinkItemDeleteModal
              isDraftFlow
              isOpen={showRemoveFlowModal}
              sync={currentLink}
              onCancel={() => setShowRemoveFlowModal(false)}
              providerA={providerA}
              providerB={providerB}
              onRequestClose={() => setShowRemoveFlowModal(false)}
              onDeleteSync={() => history.push(`${routes.ABSOLUTE_PATHS.DASHBOARD}`)}
            />
          )}
          {(linkId || workflowId) && (
            <DeleteButton
              startIcon="trash"
              variant="subtle"
              loading={workflowResourcesLoading}
              onClick={() => setShowRemoveFlowModal(!showRemoveFlowModal)}
            >
              {workflowId && !isDeletable ? 'Remove' : 'Delete'} this {isDraftFlow ? 'draft' : 'flow'}
            </DeleteButton>
          )}
        </>
      </PageContainer>
      {!isDraftFlow && (
        <NoPaddingGrid>
          <Row nogutter>
            <Col md={12} lg={12}>
              <Box m={[tokens.spacing.s5, tokens.spacing.s4, tokens.spacing.s6, tokens.spacing.s4]}>
                <SyncStatusFormActivityStream link={currentLink} changePage={onReviewRulesPageChange} />
              </Box>
            </Col>
          </Row>
        </NoPaddingGrid>
      )}
    </>
  );
};

Guide.propTypes = {
  isDuplicating: PropTypes.bool.isRequired,
  loadingState: PropTypes.oneOf(Object.values(loadingStates)).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ linkId: PropTypes.string }).isRequired,
    url: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
};
