import React, { useContext, useEffect, useState, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Alert, Box, Button, tokens } from '@unitoio/mosaic';

import {
  getLinkContainerBySide,
  getLinkProviderNameBySide,
  getAreProviderCapabilitiesForItemsLoaded,
  getSupportedFieldsForItem,
  getProviderByName,
} from 'reducers';
import * as fieldTypes from '~/consts/fields';
import * as linkTypes from '~/consts/link';
import * as trackingTypes from '~/consts/tracking';
import * as routes from '~/consts/routes';
import { FlowBuilderErrorContext } from '~/contexts';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { useGetItemTypes } from '~/hooks/useGetItemTypes';
import { Href } from '~/components/Href/Href';
import { ProviderTermsByName } from '~/components/ProviderTerms/ProviderTermsByName';

import { getAreModernRulesEnabled } from '../../utils/getAreModernRulesEnabled';
import * as formUtils from '../../utils/form';
import { PageContainer } from '../PageContainer/PageContainer';
import { PageHeader } from '../../components/PageHeader';
import { ManageDuplicates } from '../../components/ManageDuplicates';
import { useProviderItemSupportsMerging } from '../../hooks/useProviderItemSupportsMerging';
import { useGetContainerTypes } from '../../hooks/useGetContainerTypes';
import { useAreBothSidesMergeable } from '../../hooks/useAreBothSidesMergeable';
import { DuplicateBanner } from '../../components/DuplicateBanner';
import { RuleSidePanel } from '../SidePanels/RuleSidePanel';
import { MergeDuplicatesWarning } from './MergeDuplicatesWarning/MergeDuplicatesWarning';
import {
  useGetSides,
  useSetDefaultFieldValues,
  useInitializeDefaultRules,
  useGetCustomFields,
  useSetSyncDirection,
} from './hooks';
import { RulesSection } from './RulesSection/RulesSection';
import { useIsFlowDuplicate } from '../../hooks';

const OpenSidePanelLink = styled.span`
  color: ${tokens.colors.content.info.default};
  cursor: pointer;
`;

const statuses = {
  INITIAL: 'initial',
  LOADED: 'loaded',
  STANDBY: 'standby',
  SUCCESS: 'success',
  SUCCESS_DFV: 'successDefaultFieldValue',
  SUCCESS_RULES: 'successDefaultRules',
  SUCCESS_DUPLICATE: 'successDuplicateRules',
  LOADING: 'loading',
  ERROR_CF: 'errorCustomFields',
  ERROR_DFV: 'errorDefaultFieldValue',
};

export const Rules = ({ loadingState, match }) => {
  const { linkId } = match.params;
  const linkState = useWatch({ name: 'state' });
  const isDraftFlow = linkId && linkState === linkTypes.LINK_STATES.DRAFT;
  const {
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useFormContext();
  const isDuplicate = useIsFlowDuplicate();
  const [itemTypeA, itemTypeB] = useGetItemTypes(linkId);
  const [containerTypeA, containerTypeB] = useGetContainerTypes(linkId);
  const providerNameA = useSelector((state) => getLinkProviderNameBySide(state, { containerSide: 'A', linkId }));
  const providerNameB = useSelector((state) => getLinkProviderNameBySide(state, { containerSide: 'B', linkId }));
  const providerA = useSelector((state) => getProviderByName(state, providerNameA));
  const providerB = useSelector((state) => getProviderByName(state, providerNameB));
  const containerA = useSelector((state) => getLinkContainerBySide(state, { containerSide: 'A', linkId }));
  const containerB = useSelector((state) => getLinkContainerBySide(state, { containerSide: 'B', linkId }));
  const providerFieldsA = useSelector((state) =>
    getSupportedFieldsForItem(state, providerA.get('_id'), containerA.get('id'), itemTypeA),
  );

  const providerFieldsB = useSelector((state) =>
    getSupportedFieldsForItem(state, providerB.get('_id'), containerB.get('id'), itemTypeB),
  );
  const areProvidersCapabilitiesForItemsLoaded = useSelector((state) =>
    getAreProviderCapabilitiesForItemsLoaded(
      state,
      providerA.get('_id'),
      providerB.get('_id'),
      containerA.get('id'),
      containerB.get('id'),
      itemTypeA,
      itemTypeB,
    ),
  );

  const sides = useGetSides(linkId, containerA);
  useGetCustomFields({
    containerIdA: sides.A.containerId,
    containerIdB: sides.B.containerId,
    providerIdentityIdA: sides.A.providerIdentityId,
    providerIdentityIdB: sides.B.providerIdentityId,
    providerNameA,
    providerNameB,
    itemTypeA,
    itemTypeB,
  });

  // TODO EOC fix - review hooks in upcoming days w/ ashouc
  const defaultValuesLoadingStates = useSetDefaultFieldValues({
    formSides: sides,
    isDraft: isDraftFlow,
    loadingState,
    providerFieldsA,
    providerFieldsB,
    areProvidersCapabilitiesForItemsLoaded,
    itemTypeA,
    itemTypeB,
    containerTypeA,
    containerTypeB,
  });

  const initializeFieldsStates = useInitializeDefaultRules({
    sides,
    isDuplicatedLink: isDuplicate,
    isDraft: isDraftFlow,
    areActionsLoaded:
      defaultValuesLoadingStates === statuses.SUCCESS_DFV || defaultValuesLoadingStates === statuses.ERROR_DFV,
    providerFieldsA,
    providerFieldsB,
  });

  const pageName = useContext(FlowBuilderErrorContext);
  const isLoading = [statuses.INITIAL, statuses.LOADING, statuses.STANDBY].includes(initializeFieldsStates);

  // If we find a merge missing settings error, ignore it in this component (to not block the CONFIRM)
  // we want to allow users to keep editing their drafts freely, instead the LaunchFlow modal will
  // remind users to set this value.
  const { useMergeMissingSettings, ...otherErrors } = errors[pageName] || {};
  const hasRulesErrors = !!Object.keys(otherErrors)?.length;
  const shouldConfirmButtonBeDisabled =
    isLoading ||
    hasRulesErrors ||
    [formUtils.loadingStates.LOADING, formUtils.loadingStates.SAVING].includes(loadingState);

  // previously known as contact based
  const providerItemASupportsMerge = useProviderItemSupportsMerging(providerNameA, itemTypeA);
  const providerItemBSupportsMerge = useProviderItemSupportsMerging(providerNameB, itemTypeB);
  const oneItemSupportsMerge = providerItemASupportsMerge || providerItemBSupportsMerge;

  const showSyncDirectionAlert = useSetSyncDirection({ providerNameA, providerNameB, watch, setValue });
  const bothSidesMergeable = useAreBothSidesMergeable({ linkId, itemTypeA, itemTypeB, providerNameA, providerNameB });

  const isEarliestCreatedAtFieldDisplayed =
    sides.A.fields.some((field) => field.fieldId === fieldTypes.EARLIEST_CREATED_AT) ||
    sides.B.fields.some((field) => field.fieldId === fieldTypes.EARLIEST_CREATED_AT);

  const initialIsEarliestCreatedAtFieldDisplayedRef = useRef(isEarliestCreatedAtFieldDisplayed);

  const areModernRulesEnabled = getAreModernRulesEnabled(sides.A) || getAreModernRulesEnabled(sides.B);
  const trackEvent = useTrackEvent({ selected_tool_names: `${providerNameA}, ${providerNameB}` });
  useEffect(() => {
    if (initialIsEarliestCreatedAtFieldDisplayedRef.current) {
      trackEvent(trackingTypes.START, {
        limit: 'test mode',
      });
      // since the tracking for modern rules happens in Mosaic, we need to skip it here to avoid double events
    } else if (!areModernRulesEnabled) {
      trackEvent(trackingTypes.START);
    }
  }, [trackEvent, areModernRulesEnabled]);

  const handleOnSubmitClick = async () => {
    trackEvent(trackingTypes.SUBMIT);
    await handleSubmit({ redirectPage: routes.FLOW_BUILDER_PAGES.MAPPINGS });
  };

  const directionValue = watch('syncDirection');

  const getPluralProviderTerms = (shouldCapitalize = false) => (
    <ProviderTermsByName
      providerNameA={providerNameA}
      providerNameB={providerNameB}
      plurality="plural"
      termKey="task"
      pcdv3
      itemTypeA={itemTypeA}
      itemTypeB={itemTypeB}
      textTransform={shouldCapitalize ? 'capitalize' : undefined}
    />
  );

  const soloRuleSection = directionValue === fieldTypes.TARGET.A ? { B: sides.B } : { A: sides.A };
  const ruleSections =
    directionValue === fieldTypes.TARGET.BOTH ? Object.entries(sides) : Object.entries(soloRuleSection);
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  return (
    <PageContainer>
      <PageHeader
        title={<>Select the {getPluralProviderTerms()} you want to sync</>}
        subtitle={
          <>
            Add rules to restrict which {getPluralProviderTerms()} Unito syncs. {getPluralProviderTerms(true)} must
            always meet the rules you create to continue syncing.{' '}
            <OpenSidePanelLink
              onClick={() => {
                setIsSidePanelOpen(true);
                trackEvent(trackingTypes.ACTION, {
                  action_name: trackingTypes.FLOW_BUILDER_SIDE_PANEL.ACTIONS.NEED_HELP,
                  selected_tool_name: `${providerNameA},${providerNameB}`,
                });
              }}
            >
              Need some help setting up your rules?
            </OpenSidePanelLink>
            <RuleSidePanel isOpen={isSidePanelOpen} onClose={() => setIsSidePanelOpen(false)} linkId={linkId} />
          </>
        }
      />
      <DuplicateBanner />

      {showSyncDirectionAlert && (
        <Box m={[tokens.spacing.s4, 0]}>
          <Alert size="sm">
            With the tools and the rules you have chosen, you can only do one-way flows. To learn more, see{' '}
            <Href href="https://guide.unito.io/how-to-pick-the-best-block-of-work-for-your-use-case">
              how to choose the best block of work
            </Href>{' '}
            for your use case.
          </Alert>
        </Box>
      )}

      {!isLoading && bothSidesMergeable && <ManageDuplicates />}

      {ruleSections.map(([side]) => (
        <RulesSection
          key={`rules-section-${side}`}
          side={side}
          sides={sides}
          linkState={linkState}
          isLoading={isLoading}
          isAutoSaving={loadingState === formUtils.loadingStates.SAVING}
        />
      ))}

      {oneItemSupportsMerge && !bothSidesMergeable && <MergeDuplicatesWarning />}

      {isDraftFlow && (
        <Box justifyContent="flex-end" m={[tokens.spacing.s7, 0, 0, 0]}>
          <Button
            type="submit"
            disabled={shouldConfirmButtonBeDisabled}
            loading={loadingState === formUtils.loadingStates.SAVING}
            onClick={handleOnSubmitClick}
          >
            Confirm
          </Button>
        </Box>
      )}
    </PageContainer>
  );
};

Rules.propTypes = {
  loadingState: PropTypes.oneOf(Object.values(formUtils.loadingStates)).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({ linkId: PropTypes.string }).isRequired,
  }).isRequired,
};
