import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';

import { Box, Icon, NewTag, NewTypography, Tooltip, tokens } from '@unitoio/mosaic';

import { getItemTypeBySide, getLinkById, getLinkProviderNameBySide } from 'reducers';
import { ProviderTermsByName } from '~/components/ProviderTerms/ProviderTermsByName';
import { TurnOffTestModeModal } from '~/components/TurnOffTestModeModal/TurnOffTestModeModal';
import * as linkActions from '~/actions/links';
import { useGetTestModeTerm } from '../hooks/useGetTestModeTerm';

const StyledTrashIcon = styled(Icon)`
  cursor: pointer;
`;

export const TurnOffTestModeBox = () => {
  const { linkId } = useParams();
  const link = useSelector((state) => getLinkById(state, linkId));

  const itemTypeA = useSelector((state) => getItemTypeBySide(state, linkId, 'A'));
  const itemTypeB = useSelector((state) => getItemTypeBySide(state, linkId, 'B'));

  const providerNameA = useSelector((state) => getLinkProviderNameBySide(state, { linkId, containerSide: 'A' }));
  const providerNameB = useSelector((state) => getLinkProviderNameBySide(state, { linkId, containerSide: 'B' }));

  const testModeTerm = useGetTestModeTerm(linkId);

  const earliestCreatedAt = link.getIn(['syncSettings', 'earliestCreatedAt']);
  const formattedLinkEarliestCreatedAt = moment(earliestCreatedAt).format('MMMM D, YYYY');

  const isSyncOnlyNewTasks = !!earliestCreatedAt;

  const [isTurnOffTestModeModalOpen, setIsTurnOffTestModeModalOpen] = useState(false);

  const dispatch = useDispatch();

  const handleClickTrashIcon = useCallback(() => {
    setIsTurnOffTestModeModalOpen(true);
  }, [setIsTurnOffTestModeModalOpen]);

  const handleOnCloseModal = useCallback(() => {
    setIsTurnOffTestModeModalOpen(false);
  }, [setIsTurnOffTestModeModalOpen]);

  const handleOnTurnOffTestMode = useCallback(async () => {
    await dispatch(linkActions.turnOffTestMode(linkId));
    handleOnCloseModal();
  }, [dispatch, handleOnCloseModal, linkId]);

  const providerTerms = useMemo(
    () => (
      <ProviderTermsByName
        providerNameA={providerNameA}
        providerNameB={providerNameB}
        itemTypeA={itemTypeA}
        itemTypeB={itemTypeB}
        pcdv3={!!itemTypeA && !!itemTypeB}
        plurality="plural"
        termKey="task"
        fallbackTerm="items"
      />
    ),
    [itemTypeA, itemTypeB, providerNameA, providerNameB],
  );

  return (
    isSyncOnlyNewTasks && (
      <Box
        flexDirection={Box.flexDirection.ROW}
        justifyContent={Box.justifyContent.SPACE_BETWEEN}
        borderRadius={tokens.spacing.s4}
        borderSize={1}
        p={[tokens.spacing.s4]}
      >
        <Box>
          <NewTypography.Text>
            <NewTag color="blue">Only {testModeTerm}</NewTag> Only sync {providerTerms} created on or after{' '}
            <NewTypography.Text strong>{formattedLinkEarliestCreatedAt}</NewTypography.Text>
          </NewTypography.Text>
        </Box>

        <Box m={[0, tokens.spacing.s4]}>
          <Tooltip
            content={`Remove 'only ${testModeTerm} items' setting. This action is irreversible and cannot be undone.`}
          >
            <StyledTrashIcon name="trash" kind={Icon.KINDS.SOLID} onClick={handleClickTrashIcon} />
          </Tooltip>
        </Box>

        <TurnOffTestModeModal
          isOpen={isTurnOffTestModeModalOpen}
          taskTerm={providerTerms}
          onRequestClose={handleOnCloseModal}
          onSuccess={handleOnTurnOffTestMode}
          linkId={linkId}
        />
      </Box>
    )
  );
};
