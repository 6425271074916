import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  Bold,
  Box,
  Col,
  Collapsible,
  Flex,
  Grid,
  Icon,
  ProviderIcon,
  Row,
  Tooltip,
  Typography,
  tokens,
} from '@unitoio/mosaic';

import * as featureTypes from '~/consts/features';
import * as trackingTypes from '~/consts/tracking';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { getFeatureFlagValue, getLinkById, getCustomFields, getFieldAssociations, getNativeFields } from 'reducers';
import { getActivityStatusOption } from '~/utils/getActivityStatusOption';
import { capitalize } from '~/utils/capitalize';
import { otherSide } from '~/utils/otherSide';

import { FailedActivityItem } from './ActivityItemCollapsibles/FailedActivityItem';

const statusNames = {
  SUCCESS: 'success',
  FAILED: 'failed',
  RETRYING: 'retrying',
};

const StyledStatusIcon = styled(Icon)`
  margin-right: ${tokens.spacing.s3};
`;

const statusTooltipText = {
  [statusNames.SUCCESS]: 'We were able to successfully complete this sync.',
  [statusNames.FAILED]: 'We were unsuccessful in attempting to complete this sync.',
  [statusNames.RETRYING]:
    'We encountered an issue while trying to complete this sync, but we will make another attempt.',
};

const StatusIcon = (activityStatusOption) => {
  const currentStatusIcon = {
    [statusNames.SUCCESS]: (
      <StyledStatusIcon name="circle-check" color={tokens.colors.content.positive.default} kind={Icon.KINDS.SOLID} />
    ),
    [statusNames.FAILED]: (
      <StyledStatusIcon name="circle-xmark" color={tokens.colors.content.destructive.default} kind={Icon.KINDS.SOLID} />
    ),
    [statusNames.RETRYING]: (
      <StyledStatusIcon name="clock-rotate-left" color={tokens.colors.content.neutral.n30} kind={Icon.KINDS.SOLID} />
    ),
  }[activityStatusOption];
  return currentStatusIcon ?? null;
};

/**
 * For a given field id A, get the id of its associated field B.
 *
 * For example, given the following field associations:
 *
 *  const associations = [{
 *    A: { field: 'foo' },
 *    B: { field: 'bar' },
 *  }]
 *
 * getAssociatedFieldId(associations, 'A', 'foo') # => 'bar'
 * getAssociatedFieldId(associations, 'B', 'bar') # => 'foo'
 * getAssociatedFieldId(associations, 'A', 'nope') # => null
 *
 */
const getAssociatedFieldId = (associations, sourceSide, fieldId) => {
  let mappedFieldId = fieldId;

  if (mappedFieldId.startsWith('CF__')) {
    mappedFieldId = fieldId.substr('CF__'.length);
  }

  const association = associations.find((a) => a.getIn([sourceSide, 'field']) === mappedFieldId);

  return association?.getIn([otherSide(sourceSide), 'field']) ?? null;
};

/**
 * For a given field id, get its singular capitalized name.
 *
 * The field may be native, custom or special (ex: taskType).
 * When the field name is not found, fallback to its id.
 *
 */
const getFieldName = (fieldId, nativeFields, customFields) => {
  let fieldName;

  if (!fieldId) {
    fieldName = '<unknown>';

    // In the activity log, a custom field has a CF__ prefix.
  } else if (fieldId.startsWith('CF__')) {
    fieldName = customFields.getIn([fieldId.substr('CF__'.length), 'name'], null) ?? fieldId;

    // Special field: the description footer.
  } else if (fieldId === 'linkBlockData') {
    fieldName = 'description footer';

    // Special field: the task type.
  } else if (fieldId === 'taskType') {
    fieldName = 'task type';

    // Native or custom field.
  } else {
    fieldName =
      nativeFields.getIn([fieldId, 'names', 'singular'], null) ??
      customFields.getIn([fieldId, 'name'], null) ??
      fieldId;
  }

  return capitalize(fieldName);
};

export const ActivityItem = ({ activityLog }) => {
  const trackEvent = useTrackEvent();
  const isErrorDetailsEnabled = useSelector((state) =>
    getFeatureFlagValue(state, featureTypes.FEATURES.SYNC_OBSERVABILITY_ERROR_DETAILS),
  );

  const { publisherError, status, linkId, targetContainer } = activityLog;

  const activityStatusOption = getActivityStatusOption(status, publisherError);

  const [isExpanded, setIsExpanded] = useState(false);

  const link = useSelector((state) => getLinkById(state, linkId));

  const nativeFieldsA = useSelector((state) =>
    getNativeFields(state, {
      providerName: link.getIn(['A', 'providerName']),
      containerId: link.getIn(['A', 'container', 'id']),
      itemType: link.getIn(['A', 'itemType']),
    }),
  );

  const nativeFieldB = useSelector((state) =>
    getNativeFields(state, {
      providerName: link.getIn(['B', 'providerName']),
      containerId: link.getIn(['B', 'container', 'id']),
      itemType: link.getIn(['B', 'itemType']),
    }),
  );

  const targetSide = targetContainer.id === link.getIn(['A', 'container', 'id']) ? 'A' : 'B';

  const fieldAssociations = useSelector((state) => getFieldAssociations(state, linkId));

  const targetNativeFields = targetSide === 'A' ? nativeFieldsA : nativeFieldB;

  const targetCustomFields = useSelector((state) =>
    getCustomFields(state, {
      containerSide: targetSide,
    }),
  );

  const sourceNativeFields = targetSide === 'A' ? nativeFieldB : nativeFieldsA;

  const sourceCustomFields = useSelector((state) =>
    getCustomFields(state, {
      containerSide: otherSide(targetSide),
    }),
  );

  const handleOnToggle = () => {
    if (!isExpanded) {
      setIsExpanded(true);
      trackEvent(trackingTypes.ACTION, {
        action_name: trackingTypes.ACTIVITY_LOG.ACTIONS.CLICKED_DROPDOWN,
      });
    } else {
      setIsExpanded(false);
    }
  };

  if (activityStatusOption === statusNames.SUCCESS) {
    return (
      <Box
        borderSize={1}
        borderColor={tokens.colors.strokes.message.positive}
        borderRadius={tokens.spacing.s3}
        p={[tokens.spacing.s3]}
        m={[0, 0, tokens.spacing.s4, 0]}
      >
        <Collapsible
          onToggle={handleOnToggle}
          defaultIsOpen={false}
          header={
            <Grid>
              <Row>
                <Col xs={3}>
                  <Tooltip
                    content={
                      statusTooltipText[activityStatusOption] ||
                      "This item's status is currently unavailable; it may still be syncing."
                    }
                  >
                    <Box alignItems={Box.alignItems.CENTER}>
                      <Typography>
                        {StatusIcon(activityStatusOption)} {capitalize(activityStatusOption)}
                      </Typography>
                    </Box>
                  </Tooltip>
                </Col>
                <Col xs={9}>
                  <Typography>{moment(activityLog.changeTime).format('D MMM | h:mm:ss A')}</Typography>
                </Col>
              </Row>
            </Grid>
          }
        >
          <Box m={[tokens.spacing.s4, 0]}>
            <Typography variant={Typography.variants.BODY1}>
              <Bold>{activityLog.type === 'Create' ? 'Created' : 'Updated'} information</Bold>
            </Typography>
          </Box>
          <Box m={[tokens.spacing.s4]}>
            <Flex vertical m={[tokens.spacing.s4, 0]} gap={tokens.spacing.s4}>
              {activityLog.fields.map((targetField) => (
                <Flex align="center" gap={tokens.spacing.s3} key={targetField}>
                  <ProviderIcon name={activityLog.sourceConnectorName} size="default" />
                  <Box
                    p={[tokens.spacing.s2]}
                    borderRadius={tokens.spacing.s3}
                    backgroundColor={tokens.colors.background.neutral.grey}
                  >
                    <Typography variant={Typography.variants.BODY2}>
                      {getFieldName(
                        getAssociatedFieldId(fieldAssociations, targetSide, targetField),
                        sourceNativeFields,
                        sourceCustomFields,
                      )}
                    </Typography>
                  </Box>
                  <Icon name="long-arrow-right" />
                  <ProviderIcon name={activityLog.targetConnectorName} size="default" />
                  <Box
                    p={[tokens.spacing.s2]}
                    borderRadius={tokens.spacing.s3}
                    backgroundColor={tokens.colors.background.neutral.grey}
                  >
                    <Typography variant={Typography.variants.BODY2}>
                      {getFieldName(targetField, targetNativeFields, targetCustomFields)}
                    </Typography>
                  </Box>
                </Flex>
              ))}
            </Flex>
          </Box>
        </Collapsible>
      </Box>
    );
  }

  if (isErrorDetailsEnabled && [statusNames.FAILED, statusNames.RETRYING].includes(activityStatusOption)) {
    return <FailedActivityItem activityLog={activityLog} handleOnToggle={handleOnToggle} getStatusIcon={StatusIcon} />;
  }

  return (
    <Box borderSize={1} borderRadius={tokens.spacing.s3} p={[tokens.spacing.s3]} m={[0, 0, tokens.spacing.s4, 0]}>
      <Grid>
        <Row>
          <Col xs={3}>
            <Typography>
              {StatusIcon(activityStatusOption)} {capitalize(activityStatusOption)}
            </Typography>
          </Col>
          <Col xs={9}>
            <Typography>{moment(activityLog.changeTime).format('D MMM | h:mm:ss A')}</Typography>
          </Col>
        </Row>
      </Grid>
    </Box>
  );
};

ActivityItem.propTypes = {
  activityLog: PropTypes.shape({
    type: PropTypes.string.isRequired,
    changeTime: PropTypes.number.isRequired,
    status: PropTypes.bool.isRequired,
    fields: PropTypes.arrayOf(PropTypes.string).isRequired,
    sourceConnectorName: PropTypes.string.isRequired,
    targetConnectorName: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    linkId: PropTypes.string.isRequired,
    targetContainer: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    publisherError: PropTypes.shape({
      retryable: PropTypes.bool,
      category: PropTypes.string,
      subCategory: PropTypes.string,
    }),
  }).isRequired,
};
