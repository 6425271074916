import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getUserProviderIdentities,
  getIsProviderIdentitiesLoading,
  getIsProviderIdentitiesLoaded,
  hasProviderIdentitiesError,
} from 'reducers';
import * as providerIdentityActions from '~/actions/providerIdentity';

export const useGetAllProvidersIdentities = () => {
  const dispatch = useDispatch();

  const isProviderIdentitiesLoading = useSelector((state) => getIsProviderIdentitiesLoading(state));
  const isProviderIdentitiesLoaded = useSelector((state) => getIsProviderIdentitiesLoaded(state));
  const hasError = useSelector((state) => hasProviderIdentitiesError(state));

  const allProviderIdentities = useSelector((state) => getUserProviderIdentities(state));

  const shouldFetchProviderIdentities = !isProviderIdentitiesLoading && !isProviderIdentitiesLoaded && !hasError;

  useEffect(() => {
    const fetchAllProviderIdentities = async () => {
      if (shouldFetchProviderIdentities) {
        await dispatch(providerIdentityActions.getProviderIdentities());
      }
    };
    fetchAllProviderIdentities();
  }, [dispatch, shouldFetchProviderIdentities]);

  return {
    allProviderIdentities,
    isLoading: isProviderIdentitiesLoading,
    isLoaded: isProviderIdentitiesLoaded,
    hasError,
  };
};
