import { useSelector } from 'react-redux';
import { Map } from 'immutable';

import { getLinkById, getActiveLinkByProviderContainers } from 'reducers';
import * as linkTypes from '~/consts/link';

export function useIsAlreadyActiveFlow(linkId) {
  const currentLink = useSelector((state) => getLinkById(state, linkId));
  const sideA = currentLink.get('A', Map());
  const sideB = currentLink.get('B', Map());
  const containerADisplayName = sideA.getIn(['container', 'displayName']);
  const containerBDisplayName = sideB.getIn(['container', 'displayName']);

  const itemTypeA = currentLink.getIn(['A', 'itemType']);
  const itemTypeB = currentLink.getIn(['B', 'itemType']);
  const containerTypeA = currentLink.getIn(['A', 'containerType']);
  const containerTypeB = currentLink.getIn(['B', 'containerType']);

  const linkWithSameContainers = useSelector((state) =>
    getActiveLinkByProviderContainers(
      state,
      {
        providerContainerId: sideA.get('providerContainer'),
        containerId: sideA.getIn(['container', 'id']),
        itemType: itemTypeA,
        containerType: containerTypeA,
      },
      {
        providerContainerId: sideB.get('providerContainer'),
        containerId: sideB.getIn(['container', 'id']),
        itemType: itemTypeB,
        containerType: containerTypeB,
      },
      [linkId],
    ),
  );

  const hasAnotherLinkWithSameContainers = !!linkWithSameContainers;

  const isAlreadyActiveSync =
    currentLink.get('state') === linkTypes.LINK_STATES.DRAFT &&
    hasAnotherLinkWithSameContainers &&
    containerADisplayName &&
    containerBDisplayName;

  return {
    isAlreadyActiveSync: !!isAlreadyActiveSync,
    linkWithSameContainers,
    containerADisplayName,
    containerBDisplayName,
  };
}
