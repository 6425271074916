import React from 'react';

import { NewButton, notification } from '@unitoio/mosaic';

import * as fieldTypes from '~/consts/fields';
import * as routes from '~/consts/routes';
import { getContainerFieldValue, getFieldAssociationByIndex, getSelectedOrganizationId } from 'reducers';
import * as formUtils from '~/utils/forms';

export const getCustomFields =
  ({ containerSide, containerId, providerIdentityId, itemType, getUncached = false }) =>
  async (dispatch, getState) => {
    const state = getState();
    const cId = containerId || getContainerFieldValue(state, { containerSide }, 'containerId');
    const piId = providerIdentityId || getContainerFieldValue(state, { containerSide }, 'providerIdentityId');
    let response;
    try {
      response = await dispatch({
        displayError: false,
        types: [
          fieldTypes.GET_CUSTOM_FIELDS_REQUEST,
          fieldTypes.GET_CUSTOM_FIELDS_SUCCESS,
          fieldTypes.GET_CUSTOM_FIELDS_FAILURE,
        ],
        meta: {
          containerSide,
          containerId: cId,
          providerIdentityId: piId,
        },
        url: routes.API_PATHS.GET_CUSTOM_FIELDS(piId, cId, itemType, getUncached),
      });
    } catch (err) {
      notification.error({
        message: 'Your tool configuration is incomplete',
        description: err.message,
        placement: 'top',
        btn: (
          <NewButton
            onClick={() => window.open(`https://guide.unito.io/kb-search-results?term=${err.message}`, '_blank')}
          >
            Guide
          </NewButton>
        ),
      });
      throw err;
    }
    return response;
  };

export const fetchFieldValue =
  ({ containerSide, fieldId, fieldValueId, kind, parentField, itemType, ...rest }) =>
  (dispatch, getState) => {
    const state = getState();
    // containerId and providerIdentityId are passed directly in the multisync creation form
    // FIXME: Make all components calling fetchFieldValues pass the containerId and providerIdentityId as params
    const containerId = rest.containerId || getContainerFieldValue(state, { containerSide }, 'containerId');
    const providerIdentityId =
      rest.providerIdentityId || getContainerFieldValue(state, { containerSide }, 'providerIdentityId');
    return dispatch({
      types: [
        fieldTypes.GET_FIELD_VALUE_REQUEST,
        fieldTypes.GET_FIELD_VALUE_SUCCESS,
        fieldTypes.GET_FIELD_VALUE_FAILURE,
      ],
      url: routes.API_PATHS.GET_FIELD_VALUE(
        // eslint-disable-line
        providerIdentityId,
        containerId,
        fieldId,
        kind,
        fieldValueId,
        parentField ?? itemType,
      ),
      meta: {
        containerId,
        fieldId,
        fieldValueId,
        kind,
        providerIdentityId,
        containerSide,
      },
    });
  };

export const fetchFieldValues =
  ({ category, containerSide, containerType, fieldId, kind, parentField, searchString, itemType, ...rest }) =>
  (dispatch, getState) => {
    const state = getState();
    // FIXME: Make all components calling fetchFieldValues pass the containerId and providerIdentityId as params
    const containerId = rest.containerId || getContainerFieldValue(state, { containerSide }, 'containerId');
    const providerIdentityId =
      rest.providerIdentityId || getContainerFieldValue(state, { containerSide }, 'providerIdentityId');

    let filters;
    if (rest.fetchDefault) {
      filters = formUtils.toLinkPayloadFlowBuilderFilters(rest.filters);
    }

    return dispatch({
      types: [
        fieldTypes.GET_FIELD_VALUES_REQUEST,
        fieldTypes.GET_FIELD_VALUES_SUCCESS,
        fieldTypes.GET_FIELD_VALUES_FAILURE,
      ],
      url: routes.API_PATHS.GET_FIELD_VALUES({
        providerIdentityId,
        containerId,
        containerType,
        fieldId,
        kind: parentField ? fieldTypes.KINDS.PCD_TYPED_ITEM_FIELD : kind,
        category,
        searchString,
        fetchDefault: rest.fetchDefault,
        filters,
        itemType: parentField ?? itemType,
      }),
      meta: {
        providerIdentityId,
        containerId,
        fieldId,
        kind,
        containerSide,
        category,
        searchString,
      },
    });
  };

export const automapFieldValuesMapping =
  ({
    containerIdA,
    containerIdB,
    providerIdentityIdA,
    providerIdentityIdB,
    itemTypeA,
    itemTypeB,
    fieldAssociation: association,
    entity,
    fieldAssociationIndex,
    multisyncLeafSide,
    linkId,
  }) =>
  (dispatch, getState) => {
    const state = getState();
    const fieldAssociation = association || getFieldAssociationByIndex(state, { index: fieldAssociationIndex, linkId });
    const canAutomap = !!(
      fieldAssociation.getIn(['A', 'kind']) &&
      fieldAssociation.getIn(['A', 'field']) &&
      fieldAssociation.getIn(['B', 'kind']) &&
      fieldAssociation.getIn(['B', 'field'])
    );

    if (!canAutomap) {
      return undefined;
    }

    return dispatch({
      method: routes.METHODS.POST,
      types: [
        fieldTypes.AUTOMAP_FIELD_VALUES_REQUEST,
        fieldTypes.AUTOMAP_FIELD_VALUES_SUCCESS,
        fieldTypes.AUTOMAP_FIELD_VALUES_FAILURE,
      ],
      url: routes.API_PATHS.AUTOMAP_FIELD_VALUES,
      payload: {
        containerIdA,
        providerIdentityIdA,
        fieldIdA: fieldAssociation.getIn(['A', 'field']),
        kindA: fieldAssociation.getIn(['A', 'kind']),
        typeA: fieldAssociation.getIn(['A', 'type']),
        workflowA: fieldAssociation.getIn(['A', 'mappingCategory']) || 'default',
        itemTypeA,
        containerIdB,
        providerIdentityIdB,
        fieldIdB: fieldAssociation.getIn(['B', 'field']),
        kindB: fieldAssociation.getIn(['B', 'kind']),
        typeB: fieldAssociation.getIn(['B', 'type']),
        workflowB: fieldAssociation.getIn(['B', 'mappingCategory']) || 'default',
        itemTypeB,
      },
      meta: {
        containerIdA,
        containerIdB,
        providerIdentityIdA,
        providerIdentityIdB,
        fieldAssociation,
        entity,
        fieldAssociationIndex,
        multisyncLeafSide,
        linkId,
      },
    });
  };

export const generateValueMappingOnFieldAssociations = (linkId) => ({
  method: routes.METHODS.POST,
  types: [
    fieldTypes.GENERATE_VALUE_MAPPING_ON_FIELD_ASSOCIATIONS_REQUEST,
    fieldTypes.GENERATE_VALUE_MAPPING_ON_FIELD_ASSOCIATIONS_SUCCESS,
    fieldTypes.GENERATE_VALUE_MAPPING_ON_FIELD_ASSOCIATIONS_FAILURE,
  ],
  url: routes.API_PATHS.GENERATE_VALUE_MAPPING_ON_FIELD_ASSOCIATIONS(linkId),
  displayError: false,
  meta: { linkId },
});

export const generateFieldAssociations =
  (A, B, syncDirection, linkId = undefined) =>
  (dispatch, getState) => {
    const organizationId = getSelectedOrganizationId(getState());
    return dispatch({
      method: routes.METHODS.POST,
      types: [
        fieldTypes.GENERATE_FIELD_ASSOCIATIONS_REQUEST,
        fieldTypes.GENERATE_FIELD_ASSOCIATIONS_SUCCESS,
        fieldTypes.GENERATE_FIELD_ASSOCIATIONS_FAILURE,
      ],
      url: routes.API_PATHS.GENERATE_FIELD_ASSOCIATIONS(linkId),
      displayError: false,
      payload: {
        A,
        B,
        organizationId,
        syncDirection,
      },
      meta: { A, B, organizationId, linkId },
    });
  };

export const resetStore = () => ({
  type: fieldTypes.RESET_STORE,
});

export const getCapabilitiesFieldTypes = () => ({
  method: routes.METHODS.GET,
  types: [
    fieldTypes.GET_CAPABILITIES_FIELD_TYPES_REQUEST,
    fieldTypes.GET_CAPABILITIES_FIELD_TYPES_SUCCESS,
    fieldTypes.GET_CAPABILITIES_FIELD_TYPES_FAILURE,
  ],
  url: routes.API_PATHS.GET_CAPABILITIES_FIELD_TYPES,
});
