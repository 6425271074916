export function getPlanTier(planId) {
  if (planId.includes('trial')) return BillingPlanTier.TRIAL;
  if (planId.includes('starter') || planId.includes('personal')) return BillingPlanTier.PERSONAL;
  if (planId.includes('team')) return BillingPlanTier.TEAM;
  if (planId.includes('custom-invoiced') || planId.includes('enterprise')) return BillingPlanTier.ENTERPRISE;
  if (planId.includes('wrike')) return BillingPlanTier.FREE_WITH_WRIKE;
  if (planId.includes('pause')) return BillingPlanTier.PAUSE;
  if (planId.includes('business')) {
    if (planId.includes('x')) return BillingPlanTier.BUSINESS_PLUS;
    return BillingPlanTier.BUSINESS;
  }
  if (planId.includes('company')) {
    if (planId.includes('plus')) return BillingPlanTier.COMPANY_PLUS;
    return BillingPlanTier.COMPANY;
  }
  return null;
}

export const BillingPlanTier = {
  TRIAL: 'Trial',
  PERSONAL: 'Personal',
  TEAM: 'Team',
  BUSINESS_PLUS: 'Business +',
  BUSINESS: 'Business',
  COMPANY_PLUS: 'Company +',
  COMPANY: 'Company',
  ENTERPRISE: 'Enterprise',
  FREE_WITH_WRIKE: 'Free with Wrike',
  PAUSE: 'Pause',
};
