import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { useFormState, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { Box, tokens, Typography, Tooltip } from '@unitoio/mosaic';

import { FlowBuilderErrorContext } from '~/contexts';
import { getLinkById, getProviderIdentityById, getFeatureFlagValue } from 'reducers';
import * as linkTypes from '~/consts/link';
import * as routes from '~/consts/routes';
import * as featureTypes from '~/consts/features';
import { useGetItemTypes } from '~/hooks/useGetItemTypes';
import { Href } from '~/components/Href/Href';
import { ProviderAvatar } from '~/components/ProviderAvatar/ProviderAvatar';

import { GuideStep } from './GuideStep';
import { GuideStepTitleWithTime } from './GuideStepTitleWithTime';
import { useGetLabelAndStatusFromAnomalies, PAGES } from '../../hooks/useGetAnomalies';
import { useGetContainerTypes } from '../../hooks/useGetContainerTypes';
import { useGetProviderExtraStep } from '../../hooks/useGetProviderExtraStep';
import { useHasMissingExtraStepAction } from '../../hooks/useHasMissingExtraStepAction';
import { useContainerLinkTrackEvent } from '../../hooks/useContainerLinkTrackEvent';
import { useHasContainerErrors } from '../../hooks/useHasContainerErrors';
import { useIsFlowDraft } from '../../hooks/useIsFlowDraft';

import ToolSelectionIcon from '../../images/steps_icons/tool_selection.svg';
import { GuideStepWorkItemLabel } from './GuideStepWorkItemLabel';

const FakeHref = styled.a`
  &:hover {
    text-decoration: none;
  }

  cursor: default;
`;

const getActionLabel = (status) => {
  if (status === 'edit') {
    return 'Edit tools';
  }

  if (status === 'error') {
    return 'Review';
  }
  return 'Continue';
};

function useGetProviderIdentities() {
  const { getValues } = useFormContext();
  const providerIdentityIdA = getValues('A.providerIdentityId');
  const providerIdentityIdB = getValues('B.providerIdentityId');
  const providerIdentityA = useSelector((state) => getProviderIdentityById(state, providerIdentityIdA));
  const providerIdentityB = useSelector((state) => getProviderIdentityById(state, providerIdentityIdB));
  return [providerIdentityA, providerIdentityB];
}

const StepIcon = ({ providerName, providerIdentity }) =>
  providerName ? (
    <ProviderAvatar
      providerName={providerName}
      username={providerIdentity.get('profileDisplayName')}
      image={providerIdentity.getIn(['profileAvatars', 0])}
    />
  ) : (
    <Box p={['calc(1rem - 1px)']} borderColor={tokens.colors.content.neutral.n10} borderRadius={tokens.spacing.s3} />
  );

StepIcon.propTypes = {
  providerName: PropTypes.string,
  providerIdentity: PropTypes.instanceOf(Map).isRequired,
};

export const GuideStepWorkItems = () => {
  const { linkId } = useParams();
  const pageName = useContext(FlowBuilderErrorContext);
  const trackEvent = useContainerLinkTrackEvent();

  const { errors } = useFormState();
  const link = useSelector((state) => getLinkById(state, linkId));
  const linkKind = useSelector((state) => getLinkById(state, linkId)).get('kind');
  const isDraft = useIsFlowDraft();
  const hasContainerErrors = useHasContainerErrors();

  const isLiteGuide = linkTypes.GUIDE_VARIANTS[linkKind] === linkTypes.GUIDE_VARIANT_TYPE.LITE;
  const isAddOnImprovements = useSelector((state) =>
    getFeatureFlagValue(state, featureTypes.FEATURES.ADD_ON_IMPROVEMENTS),
  );

  const sideA = link.get('A', Map());
  const sideB = link.get('B', Map());
  const [providerIdentityA, providerIdentityB] = useGetProviderIdentities();
  const [labelFromAnomalies, statusFromAnomalies] = useGetLabelAndStatusFromAnomalies(linkId, PAGES.CONNECT_TOOLS);

  const providerNameA = sideA.get('providerName', null);
  const providerNameB = sideB.get('providerName', null);
  const providerIdentityIdA = providerIdentityA.get('_id');
  const providerIdentityIdB = providerIdentityB.get('_id');
  const [itemTypeA, itemTypeB] = useGetItemTypes(linkId);
  const [containerTypeA, containerTypeB] = useGetContainerTypes(linkId);
  const containerIdA = sideA.getIn(['container', 'id'], null);
  const containerIdB = sideB.getIn(['container', 'id'], null);
  const providerAExtraStep = useGetProviderExtraStep(providerNameA);
  const providerBExtraStep = useGetProviderExtraStep(providerNameB);
  const containerAExtraConfigDetails = useHasMissingExtraStepAction(
    providerAExtraStep,
    providerNameA,
    providerIdentityIdA,
    containerIdA,
  );
  const containerBExtraConfigDetails = useHasMissingExtraStepAction(
    providerBExtraStep,
    providerNameB,
    providerIdentityIdB,
    containerIdB,
  );

  if (!providerNameA && !providerNameB) {
    return (
      <GuideStep
        status="current"
        actionLabel="Start here"
        actionSlug={routes.FLOW_BUILDER_PAGES.TOOL_SELECTION}
        statusLabel="Start here"
      >
        <GuideStepTitleWithTime title="1. Connect your tools" icon={ToolSelectionIcon} time={2} />
      </GuideStep>
    );
  }

  // Defaulting to Map() using `|| Map()` because the containers can be set to null in a draft (if they haven't been selected yet).
  // If it's null, Immutable will not use the default value provided in second arg!
  const containerA = sideA.get('container', Map()) || Map();
  const containerB = sideB.get('container', Map()) || Map();
  const containerNameA = containerA.get('displayName', null);
  const containerNameB = containerB.get('displayName', null);
  const providerIdentityAError = errors[pageName]?.A?.providerIdentityId;
  const providerIdentityBError = errors[pageName]?.B?.providerIdentityId;
  const containerAError = errors[pageName]?.A?.containerId;
  const containerBError = errors[pageName]?.B?.containerId;

  let status = hasContainerErrors ? 'error' : 'edit';

  if (isDraft && !hasContainerErrors) {
    status = !containerNameA || !containerNameB ? 'current' : 'done';
  }
  const shouldRenderAnomaliesFromSyncStatus = statusFromAnomalies && !hasContainerErrors;
  if (shouldRenderAnomaliesFromSyncStatus) {
    status = statusFromAnomalies;
  }

  const isStepLocked = isLiteGuide && !isDraft;

  if (isStepLocked) {
    status = 'locked';
  }

  const isContainerASelected = !!containerA.get('displayName');
  const isContainerBSelected = !!containerB.get('displayName');

  const HrefElementA = isContainerASelected ? Href : FakeHref;
  const HrefElementB = isContainerBSelected ? Href : FakeHref;

  return (
    <GuideStep
      status={status}
      actionSlug={routes.FLOW_BUILDER_PAGES.TOOL_SELECTION}
      actionLabel={shouldRenderAnomaliesFromSyncStatus ? labelFromAnomalies : getActionLabel(status)}
      statusLabel="Start here"
    >
      <Box flexDirection="column">
        <Box alignItems="center" m={[0, 0, tokens.spacing.s3, 0]}>
          <Tooltip
            content={`Open ${containerA.get('displayName')} in a new tab`}
            forceHide={!isContainerASelected}
            placement="top"
          >
            <HrefElementA
              onClick={() => trackEvent(providerNameA)}
              href={containerA.get('url')}
              disabled={!isContainerASelected}
            >
              <Box alignItems="center">
                <Box m={[0, tokens.spacing.s3, 0, 0]}>
                  <StepIcon providerName={providerNameA} providerIdentity={providerIdentityA} />
                </Box>
                <Typography
                  variant="body1"
                  color={status === 'locked' ? tokens.colors.content.neutral.n20 : tokens.colors.content.neutral.n40}
                >
                  <GuideStepWorkItemLabel
                    providerName={providerNameA}
                    providerIdentity={providerIdentityA}
                    containerName={containerNameA}
                    containerExtraConfigDetails={!isAddOnImprovements && containerAExtraConfigDetails}
                    containerError={containerAError?.error || providerIdentityAError?.error}
                    itemType={itemTypeA}
                    containerType={containerTypeA}
                  />
                </Typography>
              </Box>
            </HrefElementA>
          </Tooltip>
        </Box>
        <Box alignItems="center">
          <Tooltip
            content={`Open ${containerB.get('displayName')} in a new tab`}
            forceHide={!isContainerBSelected}
            placement="top"
          >
            <HrefElementB
              onClick={() => trackEvent(providerNameB)}
              href={containerB.get('url')}
              disabled={!isContainerBSelected}
            >
              <Box alignItems="center">
                <Box m={[0, tokens.spacing.s3, 0, 0]}>
                  <StepIcon providerName={providerNameB} providerIdentity={providerIdentityB} />
                </Box>
                <Typography
                  variant="body1"
                  color={status === 'locked' ? tokens.colors.content.neutral.n20 : tokens.colors.content.neutral.n40}
                >
                  <GuideStepWorkItemLabel
                    providerName={providerNameB}
                    providerIdentity={providerIdentityB}
                    containerName={containerNameB}
                    containerExtraConfigDetails={!isAddOnImprovements && containerBExtraConfigDetails}
                    containerError={containerBError?.error || providerIdentityBError?.error}
                    itemType={itemTypeB}
                    containerType={containerTypeB}
                  />
                </Typography>
              </Box>
            </HrefElementB>
          </Tooltip>
        </Box>
      </Box>
    </GuideStep>
  );
};
