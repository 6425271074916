import { Map, List, fromJS } from 'immutable';
import { actionTypes } from 'redux-form';

import * as draftTypes from '~/consts/drafts';
import * as fieldTypes from '~/consts/fields';
import * as linkTypes from '~/consts/link';
import * as websocketTypes from '~/consts/websocket';
import { normalizeEntitiesById } from '~/utils/normalizeEntitiesById';
import * as workflowTypes from '~/consts/workflows';

const initialCurrent = Map({
  allowedProviderIdentityIds: Map(),
  mappings: Map(),
  syncSettings: Map({
    fieldAssociations: List(),
    hasGeneratedAllFieldAssociations: false,
    hasGeneratedWorkflowFieldAssociations: false,
  }),
  isLoaded: false,
  isLoading: false,
});

export const initialState = Map({
  entities: Map(),
  /** @deprecated Use `getLinkById(linkId)` instead */
  current: initialCurrent,
  isSavingSync: false,
  isLoaded: false,
  diagnostic: Map({
    result: undefined,
    isLoading: false,
  }),
  lastCountLinks: 0,
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case linkTypes.GET_LINKS_REQUEST: {
      return state.merge({ isLoaded: false });
    }
    case linkTypes.GET_LINKS_SUCCESS: {
      const linksById = {};
      const { links } = action.payload;

      links.forEach((link) => {
        linksById[link._id] = link;
      });
      return state.merge({ isLoaded: true }).set('entities', fromJS(linksById));
    }

    case workflowTypes.GET_LINKS_BY_WORKFLOW_ID_SUCCESS: {
      const links = fromJS(action.payload.links);
      const normalizedLinks = normalizeEntitiesById(links);
      return state.merge({ isLoaded: true }).mergeIn(['entities'], fromJS(normalizedLinks));
    }

    case linkTypes.GET_LINKS_FAILURE: {
      return state.merge({ isLoaded: true });
    }

    case linkTypes.ADD_LINK_REQUEST:
    case linkTypes.SAVE_LINK_REQUEST:
    case linkTypes.PATCH_LINK_REQUEST:
    case linkTypes.SET_AUTO_SYNC_LINK_REQUEST:
    case linkTypes.SET_MANUAL_SYNC_LINK_REQUEST:
      return state.merge({ isSavingSync: true });

    case linkTypes.ADD_LINK_SUCCESS:
    case linkTypes.SET_MANUAL_SYNC_LINK_SUCCESS:
    case linkTypes.PATCH_LINK_SUCCESS:
    case linkTypes.SET_AUTO_SYNC_LINK_SUCCESS: {
      const { link } = action.payload;
      return state.merge({ isSavingSync: false }).merge({
        entities: state.get('entities').mergeIn([`${link._id}`], fromJS(link)),
      });
    }

    case linkTypes.ADD_LINK_FAILURE:
    case linkTypes.SET_AUTO_SYNC_LINK_FAILURE:
    case linkTypes.PATCH_LINK_FAILURE:
    case linkTypes.SET_MANUAL_SYNC_LINK_FAILURE:
      return state.merge({ isSavingSync: false });

    case draftTypes.CREATE_DRAFT_SUCCESS:
    case draftTypes.EDIT_DRAFT_SUCCESS:
    case linkTypes.SAVE_LINK_SUCCESS: {
      const { link } = action.payload;
      return state
        .merge({
          isLoaded: true,
          isSavingSync: false,
          entities: state.get('entities').mergeIn([`${link._id}`], fromJS(link)),
        })
        .mergeIn(['current', 'isLoaded'], true);
    }

    case linkTypes.AUTOPOPULATE_FIELDS_SUCCESS:
    case linkTypes.GET_LINK_SUCCESS: {
      const { link } = action.payload;

      const currentLinkSyncStatus = state.getIn(['entities', link._id, 'syncStatus'], Map()).toJS();
      return state
        .mergeDeep(
          fromJS({
            current: {
              isLoading: false,
              isLoaded: true,
              ...link,
              syncStatus: currentLinkSyncStatus,
            },
          }),
        )
        .setIn(['entities', link._id], fromJS({ ...link, syncStatus: currentLinkSyncStatus }));
    }

    case linkTypes.DELETE_LINK_SUCCESS: {
      const { link } = action.payload;

      // using the deleteLink actions on workflow flows might not delete the flow if it belongs to multiple workflow
      // therefore we need to keep that link in the state and not remove it
      if (link.state === 'active') {
        return state;
      }

      const currentPaginationTotal = state.getIn(['pagination', 'total']);
      const updatedTotal = currentPaginationTotal > 0 ? currentPaginationTotal - 1 : currentPaginationTotal;
      return state
        .merge({
          entities: state.get('entities').delete(`${link._id}`),
        })
        .setIn(['pagination', 'total'], updatedTotal);
    }

    case linkTypes.SYNC_LINK_SUCCESS: {
      const { link } = action.payload;
      if (!link) {
        return state;
      }

      return state.mergeDeepIn(['entities', link._id], fromJS(link));
    }

    case linkTypes.GET_LINK_REQUEST: {
      return state.set('current', initialCurrent).setIn(['current', 'isLoading'], true);
    }

    case linkTypes.GET_LINK_FAILURE: {
      return state.set('current', initialCurrent);
    }

    case linkTypes.UPDATE_SYNC_LIST_STATUSES: {
      const { payload } = action;
      const entities = state.get('entities');

      const entitiesWithSyncStatus = fromJS(payload)
        .filter((_syncStatus, linkId) => entities.has(linkId))
        .map((syncStatus, linkId) => {
          const link = entities.get(linkId);
          return link.mergeDeep({ syncStatus: syncStatus.remove('linkId') });
        });

      return state.mergeDeep({ entities: entitiesWithSyncStatus });
    }

    case linkTypes.UPDATE_CURRENT_SYNC_STATUS: {
      const {
        payload,
        meta: { topic, linkId },
      } = action;

      if (!linkId) {
        return state;
      }

      const pathToLink = ['entities', linkId, 'syncStatus'];

      if (topic !== websocketTypes.TOPICS.ANOMALIES) {
        const syncStatus = fromJS(payload[linkId] || {})
          .remove('linkId')
          .remove('organizationId');

        return state.mergeIn(pathToLink, syncStatus);
      }

      const anomalies = fromJS(payload[linkId] || {});
      const anomaliesById = normalizeEntitiesById(anomalies);
      return state.setIn([...pathToLink, 'anomaliesById'], anomaliesById);
    }

    case actionTypes.CHANGE: {
      const {
        payload,
        meta: { field, form },
      } = action;

      if (form === 'syncForm' && payload) {
        if (['A.containerId', 'A.workspaceId', 'B.containerId', 'B.workspaceId'].includes(field)) {
          return state.set('current', initialCurrent);
        }
      }

      return state;
    }

    case actionTypes.DESTROY: {
      const forms = action.meta.form || [];
      if (forms.includes('syncForm')) {
        return state.set('current', initialCurrent);
      }
      return state;
    }

    case linkTypes.DUPLICATE_SYNC_SUCCESS: {
      const { link } = action.payload;
      const fieldAssociations = fromJS(link.syncSettings.fieldAssociations);

      return state.setIn(['current', 'syncSettings', 'fieldAssociations'], fieldAssociations);
    }

    case fieldTypes.AUTOMAP_FIELD_VALUES_SUCCESS: {
      const {
        meta: { fieldAssociationIndex, entity, linkId },
        payload,
      } = action;

      if (entity !== linkTypes.ENTITY_NAME && !linkId) {
        return state;
      }

      const pathToFieldAssociations = linkId
        ? ['entities', linkId, 'syncSettings', 'fieldAssociations']
        : ['current', 'syncSettings', 'fieldAssociations'];

      const updatedState = state.mergeDeepIn(
        [...pathToFieldAssociations, fieldAssociationIndex, 'A'],
        fromJS({ mapping: payload.A.mappingIds }),
      );

      return updatedState.mergeDeepIn(
        pathToFieldAssociations.concat([fieldAssociationIndex, 'B']),
        fromJS({ mapping: payload.B.mappingIds }),
      );
    }

    case linkTypes.DIAGNOSE_LINK_REQUEST: {
      return state.setIn(['diagnostic', 'result'], undefined).setIn(['diagnostic', 'isLoading'], true);
    }
    case linkTypes.DIAGNOSE_LINK_SUCCESS: {
      const { result } = action.payload;
      return state.setIn(['diagnostic', 'result'], result).setIn(['diagnostic', 'isLoading'], false);
    }
    case linkTypes.DIAGNOSE_LINK_FAILURE: {
      return state.setIn(['diagnostic', 'result'], { error: 'error' }).setIn(['diagnostic', 'isLoading'], false);
    }
    case linkTypes.RESET_DIAGNOSTIC: {
      return state.setIn(['diagnostic', 'result'], undefined).setIn(['diagnostic', 'isLoading'], false);
    }

    case linkTypes.TURN_OFF_TEST_MODE_SUCCESS: {
      const { link } = action.payload;
      return state.merge({
        isSavingSync: false,
        current: state.get('current').setIn(['syncSettings', 'earliestCreatedAt'], null),
        entities: state.get('entities').mergeDeepIn([`${link._id}`], fromJS(link)),
      });
    }

    case linkTypes.EDIT_LINK_FIELDS_SUCCESS: {
      const { link } = action.payload;
      return state.merge({
        isLoaded: true,
        isSavingSync: false,
        entities: state.get('entities').mergeDeepIn([`${link._id}`], fromJS(link)),
      });
    }

    case fieldTypes.GENERATE_FIELD_ASSOCIATIONS_SUCCESS: {
      const { fieldAssociations } = action.payload;
      const { isWorkflow } = action.meta;

      const newFieldAssociations = state
        .getIn(['current', 'syncSettings', 'fieldAssociations'])
        .concat(fromJS(fieldAssociations));

      const newState = state.setIn(['current', 'syncSettings', 'fieldAssociations'], newFieldAssociations);

      if (isWorkflow) {
        // We set hasGeneratedWorkflowFieldAssociations to true when we fetched the FA for the workflow on the filters page
        return newState.setIn(['current', 'syncSettings', 'hasGeneratedWorkflowFieldAssociations'], true);
      }
      // We set hasGeneratedAllFieldAssociations to true when we fetched the FA for the mapping page
      return newState.setIn(['current', 'syncSettings', 'hasGeneratedAllFieldAssociations'], true);
    }

    case linkTypes.GET_COUNT_LINKS_SUCCESS: {
      const { pagination } = action.payload;
      return state
        .merge({
          isLoading: false,
        })
        .setIn(['lastCountLinks'], pagination.total);
    }

    case linkTypes.GET_COUNT_LINKS_FAILURE: {
      return state.set('isLoading', false);
    }

    case linkTypes.GET_COUNT_LINKS_REQUEST:
    default: {
      return state;
    }
  }
};

export const getLinkFiltersInitializedAtBySide = (state, linkId, side) =>
  state.getIn(['entities', linkId, 'syncSettings', side, 'filtersInitializedAt'], null);

export const getFieldAssociationByIndex = (state, { index, linkId }) => {
  if (!linkId) {
    return state.getIn(['current', 'syncSettings', 'fieldAssociations', index], Map());
  }

  const link = getLinkById(state, linkId);
  return link.getIn(['syncSettings', 'fieldAssociations', index], Map());
};

export const getSyncSettings = (state, { containerSide, linkId } = {}) => {
  const target = linkId ? ['entities', linkId] : ['current'];

  if (containerSide) {
    return state.getIn([...target, 'syncSettings', containerSide], Map());
  }

  return state.getIn([...target, 'syncSettings'], Map());
};

export const getFieldAssociations = (state, linkId) => {
  if (linkId) {
    return state.getIn(['entities', linkId, 'syncSettings', 'fieldAssociations'], List());
  }

  return state.getIn(['current', 'syncSettings', 'fieldAssociations']) || List();
};

export const getLinks = (state) => state.get('entities');

export const getSortedLinks = (state) =>
  state
    .get('entities')
    .sortBy((link) => {
      const nameB = link.get('name') || link.getIn(['A', 'container', 'displayName']) || '';
      return nameB.toLowerCase();
    })
    .toList();

export const getCurrentManualOptions = (state) => state.getIn(['current', 'syncSettings', 'manualOptions'], Map());

export const isMultiSync = (state) => state.getIn(['current', 'isMultiSync']);

export const isLoaded = (state) => state.get('isLoaded');

export const getDiagnosticResult = (state) => state.getIn(['diagnostic', 'result']);

export const isDiagnosticLoading = (state) => state.getIn(['diagnostic', 'isLoading']);

export const isSavingSync = (state) => state.get('isSavingSync');

export const getLinkContainerBySide = (state, { containerSide, linkId }) => {
  const link = getLinkById(state, linkId);
  return link.getIn([containerSide, 'container'], Map());
};

export const getLinkProviderIdentityIdBySide = (state, { containerSide, linkId }) => {
  const pathToKey = [containerSide, 'providerIdentity'];
  const link = getLinkById(state, linkId);
  return link.getIn([...pathToKey, '_id'], link.getIn(pathToKey), Map());
};

export const getLinkProviderNameBySide = (state, { containerSide, linkId }) => {
  const link = getLinkById(state, linkId);
  return link.getIn([containerSide, 'providerName'], '');
};

export const getLinkById = (state, linkId) => state.getIn(['entities', linkId], Map());

export const getLinkState = (state, linkId) => state.getIn(['entities', linkId, 'state'], '');

export const getLinkOrganizationId = (state, linkId) => {
  const link = getLinkById(state, linkId);
  const linkOrganization = link.get('organization');
  return typeof linkOrganization === 'string' ? linkOrganization : linkOrganization?.get('_id');
};

export const getLinkByProviderContainers = (state, sideA, sideB, excludedIds = [], flowState = null) => {
  const allLinks = getLinks(state);
  const sides = ['A', 'B'];
  const providerContainers = [sideA.providerContainerId, sideB.providerContainerId].filter((pc) => pc);

  return allLinks.find((link) => {
    if (excludedIds.includes(link.get('_id'))) {
      return false;
    }

    if (flowState && link.get('state') !== flowState) {
      return false;
    }

    const hasBothProviderContainers = sides.every((side) =>
      providerContainers.includes(link.getIn([side, 'providerContainer'])),
    );
    if (hasBothProviderContainers) {
      return true;
    }

    // fallback for old links
    // NOTE: When coming from the workflow designer, we store in the flow node both blocks of work, but it is possible that
    // the block on the A side of the flow node be on the B side of the link or vice versa. So we have to make sure that
    // we're matching against the right side!
    const linkContainerIdA = link.getIn(['A', 'container', 'id']);
    const linkContainerIdB = link.getIn(['B', 'container', 'id']);

    const areUsingTheSameContainer =
      (linkContainerIdA === sideA.containerId && linkContainerIdB === sideB.containerId) ||
      (linkContainerIdA === sideB.containerId && linkContainerIdB === sideA.containerId);

    if (!areUsingTheSameContainer) {
      return false;
    }

    const areUsingTheSameSides = linkContainerIdA === sideA.containerId;

    if (areUsingTheSameSides) {
      return (
        link.getIn(['A', 'itemType']) === sideA.itemType &&
        link.getIn(['A', 'containerType']) === sideA.containerType &&
        link.getIn(['B', 'itemType']) === sideB.itemType &&
        link.getIn(['B', 'containerType']) === sideB.containerType
      );
    }

    return (
      link.getIn(['A', 'itemType']) === sideB.itemType &&
      link.getIn(['A', 'containerType']) === sideB.containerType &&
      link.getIn(['B', 'itemType']) === sideA.itemType &&
      link.getIn(['B', 'containerType']) === sideA.containerType
    );
  });
};

export const getDuplicatableLinks = (state, sideA, sideB, providerIdA, providerIdB, allowedLinkIds) => {
  const allSyncs = getLinks(state);

  const allSyncsFiltered = allSyncs.filter((sync) => allowedLinkIds.includes(sync.get('_id')));

  return allSyncsFiltered
    .filter((sync) => {
      if (sync.get('state') === linkTypes.LINK_STATES.DRAFT) {
        return false;
      }
      // First letter is the existing sync
      // Second letter is the incoming block side
      const AequalsA = sync.getIn(['A', 'container', 'id']) === sideA.containerId; // we're using the unito container id (uniqueId)
      const AequalsB = sync.getIn(['A', 'container', 'id']) === sideB.containerId;

      const BequalsA = sync.getIn(['B', 'container', 'id']) === sideA.containerId;
      const BequalsB = sync.getIn(['B', 'container', 'id']) === sideB.containerId;

      const syncShareProviderContainerOnSideA = AequalsA || AequalsB;
      const syncShareProviderContainerOnSideB = BequalsA || BequalsB;

      const syncShareOneProviderContainer = syncShareProviderContainerOnSideA || syncShareProviderContainerOnSideB;

      if (!syncShareOneProviderContainer) {
        return false;
      }

      if (AequalsA) {
        return sync.getIn(['B', 'providerIdentity', 'providerId']) === providerIdB;
      }
      if (AequalsB) {
        return sync.getIn(['B', 'providerIdentity', 'providerId']) === providerIdA;
      }
      if (BequalsA) {
        return sync.getIn(['A', 'providerIdentity', 'providerId']) === providerIdB;
      }
      if (BequalsB) {
        return sync.getIn(['A', 'providerIdentity', 'providerId']) === providerIdA;
      }

      return false; // should never arrive here anyway.
    })
    .toList();
};

export const getSortedSyncsWithMultiSyncs = (state) => {
  const allSyncs = getLinks(state);
  return allSyncs
    .sortBy((sync) => {
      const syncName = sync.get('name') || sync.getIn(['A', 'container', 'displayName']) || '';
      return syncName.toLowerCase();
    })
    .toList();
};

export const getAllSyncsWithoutMultisync = (state) => {
  const allSyncs = getLinks(state);
  return allSyncs.filterNot((sync) => sync.get('multisync'));
};

export const getMultisyncDiscriminantBySide = (state, containerSide) => {
  const syncSettings = getSyncSettings(state, containerSide);
  const options = syncSettings
    .filter((field) => Map.isMap(field))
    .filter((field) => field.has('multisyncDiscriminant'));

  const fieldValue = options.first();
  if (!fieldValue) {
    return fieldValue;
  }

  const fieldId = options.keySeq().first();
  return fieldValue.merge({ fieldId });
};

export const getMultisyncDiscriminantId = (state, containerSide, fieldId) => {
  const syncSettings = getSyncSettings(state, containerSide);
  return syncSettings.getIn([fieldId, 'multisyncDiscriminant']);
};

export const getLinksByIds = (state, linkIds = new List()) => {
  const allSyncs = getLinks(state.links);
  const localLinkIds = linkIds.toJS();
  return allSyncs.filter((sync) => localLinkIds.includes(sync.get('_id').toString()));
};

export const getItemTypeBySide = (state, linkId, side) => {
  const link = getLinkById(state, linkId);
  return link.getIn([side, 'itemType']);
};

export const getMergeOptionsBySide = (state, linkId, side) => {
  const link = getLinkById(state, linkId);
  return {
    mergeFields: link.getIn(['syncSettings', side, 'merge', 'fields']),
    truthSide: link.getIn(['syncSettings', side, 'truthSide']),
  };
};

export const hasActiveLinksWithValueMapping = (state, excludeTaskSync = false) =>
  state.get('entities').some((link) => {
    const isActiveFlow = link.get('state') === linkTypes.LINK_STATES.ACTIVE;

    // early return so we don't loop on field associations for nothing. If the link is not active, then we don't care about it!
    if (!isActiveFlow || (excludeTaskSync && link.get('kind') === linkTypes.KIND.TASK_SYNC)) {
      return false;
    }

    return link
      .getIn(['syncSettings', 'fieldAssociations'], List())
      .some(
        (fieldAssociation) =>
          !fieldAssociation.getIn(['A', 'mapping'], List()).isEmpty() &&
          !fieldAssociation.getIn(['B', 'mapping'], List()).isEmpty(),
      );
  });

export const getLinkSyncStatus = (state, linkId) => state.getIn(['entities', linkId, 'syncStatus'], Map());

export const getLinkSyncStatusActivity = (state, linkId) => getLinkSyncStatus(state, linkId).get('activity');

export const getLastCountLinks = (state) => state.links.get('lastCountLinks');
