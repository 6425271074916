import { useParams } from 'react-router';
import { useWatch } from 'react-hook-form';

import * as linkTypes from '~/consts/link';

export function useIsFlowDraft(control = undefined) {
  const { linkId } = useParams();
  const linkState = useWatch({ name: 'state', control });
  if (!linkId) {
    return false;
  }

  return [linkTypes.LINK_STATES.DUPLICATE, linkTypes.LINK_STATES.DRAFT].includes(linkState);
}
