import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { useWatch } from 'react-hook-form';

import { tokens, ProviderIcon, Icon, Typography, Tooltip, Space, Box } from '@unitoio/mosaic';

import { Href } from '~/components/Href/Href';
import { GuideItemsInSyncCount } from './GuideItemsInSyncCount';
import { useContainerLinkTrackEvent } from '../../hooks/useContainerLinkTrackEvent';

const DIRECTIONS = {
  both: 'exchange',
  A: 'long-arrow-left',
  B: 'long-arrow-right',
};

const LastSyncTypo = styled(Typography)`
  color: ${tokens.colors.content.neutral.n30};
`;

export const GuideEditHeader = ({
  providerNameA,
  providerNameB,
  lastSyncRequest,
  creatorName,
  containerA,
  containerB,
  organizationId,
}) => {
  const [linkName, syncDirection] = useWatch({ name: ['name', 'syncDirection'] });
  const containerLinkTrackEvent = useContainerLinkTrackEvent();

  return (
    <Box m={[tokens.spacing.s6, 0]}>
      <Space align="center" direction="horizontal" size="middle">
        <Tooltip content={`Open ${containerA.get('displayName')} in a new tab`} placement="right">
          <Href onClick={() => containerLinkTrackEvent(providerNameA)} href={containerA.get('url')}>
            <ProviderIcon size="large" name={providerNameA} />
          </Href>
        </Tooltip>
        <Icon name={DIRECTIONS[syncDirection]} />
        <Tooltip content={`Open ${containerB.get('displayName')} in a new tab`} placement="right">
          <Href onClick={() => containerLinkTrackEvent(providerNameB)} href={containerB.get('url')}>
            <ProviderIcon size="large" name={providerNameB} />
          </Href>
        </Tooltip>
        <Space direction="vertical" size="small">
          <Typography variant="h3">{linkName}</Typography>
          <LastSyncTypo variant="body2">
            <Icon name="clock" /> Last sync: {lastSyncRequest ? moment(lastSyncRequest).fromNow() : 'Never'}
            {creatorName ? `  |  Created by ${creatorName}` : ''}
            <GuideItemsInSyncCount organizationId={organizationId} />
          </LastSyncTypo>
        </Space>
      </Space>
    </Box>
  );
};

GuideEditHeader.propTypes = {
  providerNameA: PropTypes.string.isRequired,
  providerNameB: PropTypes.string.isRequired,
  lastSyncRequest: PropTypes.string.isRequired,
  creatorName: PropTypes.string.isRequired,
  containerA: PropTypes.instanceOf(Map).isRequired,
  containerB: PropTypes.instanceOf(Map).isRequired,
  organizationId: PropTypes.string.isRequired,
};
