import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import { Box, tokens, Typography } from '@unitoio/mosaic';
import { getContainerById } from 'reducers';
import { useGetItemTypes } from '~/hooks/useGetItemTypes';

import { Step } from './Step';
import { useGetProviderExtraStep } from '../../hooks/useGetProviderExtraStep';

export const ExtraUserStep = ({ linkId }) => {
  const { watch } = useFormContext();

  const providerNameA = watch('A.providerName');
  const providerNameB = watch('B.providerName');
  const providerIdentityIdA = watch('A.providerIdentityId');
  const providerIdentityIdB = watch('B.providerIdentityId');
  const containerIdA = watch('A.containerId');
  const containerIdB = watch('B.containerId');
  const containerAType = watch('A.containerType');
  const containerBType = watch('B.containerType');

  const containerA = useSelector((state) => getContainerById(state, providerIdentityIdA, containerIdA));
  const containerB = useSelector((state) => getContainerById(state, providerIdentityIdB, containerIdB));

  const containerADisplayName = containerA.get('displayName', '');
  const containerBDisplayName = containerB.get('displayName', '');

  const providerItemAExtraStep = useGetProviderExtraStep(providerNameA);
  const providerItemBExtraStep = useGetProviderExtraStep(providerNameB);

  const [itemTypeA, itemTypeB] = useGetItemTypes(linkId);

  const extraSteps = [];

  if (providerItemAExtraStep && providerNameA && containerIdA) {
    extraSteps.push(
      <Step
        key={providerNameA}
        providerName={providerNameA}
        containerName={containerADisplayName}
        providerIdentityId={providerIdentityIdA}
        containerId={containerIdA}
        containerType={containerAType}
        itemType={itemTypeA}
      />,
    );
  }

  if (providerItemBExtraStep && providerNameB && containerIdB) {
    extraSteps.push(
      <Step
        key={providerNameB}
        providerName={providerNameB}
        containerName={containerBDisplayName}
        providerIdentityId={providerIdentityIdB}
        containerId={containerIdB}
        containerType={containerBType}
        itemType={itemTypeB}
      />,
    );
  }

  const title = extraSteps.length > 1 ? 'Set up your tools' : 'Set up your tool';

  const description =
    extraSteps.length > 1
      ? 'The tools below require an extra step before you can start building a flow and sync information.'
      : 'The tool below requires an extra step before you can start building a flow and sync information.';

  return extraSteps.length > 0 ? (
    <Box
      borderColor={tokens.colors.content.neutral.n10}
      m={[tokens.spacing.s6, 0, 0, 0]}
      p={[tokens.spacing.s6]}
      borderRadius={tokens.spacing.s4}
    >
      <Typography variant="h4">{title}</Typography>
      <Typography variant="body" color={tokens.colors.content.neutral.n30}>
        {description}
      </Typography>
      {extraSteps}
    </Box>
  ) : null;
};

ExtraUserStep.propTypes = {
  linkId: PropTypes.string,
};
