import { useSelector } from 'react-redux';

import { useGetItemTypes } from '~/hooks/useGetItemTypes';
import { getLinkProviderNameBySide, getProviderByName } from 'reducers';

export const useCanMergeSide = (linkId, side) => {
  const providerName = useSelector((state) => getLinkProviderNameBySide(state, { containerSide: side, linkId }));
  const provider = useSelector((state) => getProviderByName(state, providerName));
  const [itemTypeA, itemTypeB] = useGetItemTypes(linkId);
  const primaryItemA = provider.getIn(['capabilitiesV3', 'primaryItems', side === 'A' ? itemTypeA : itemTypeB]);
  return primaryItemA?.getIn(['item', 'canMerge'], false);
};
