import { useParams } from 'react-router';

import { useIsFlowDraft } from './useIsFlowDraft';

export function useIsFlowDuplicate(control = undefined) {
  const { mode } = useParams();

  const isDuplicatedLink = mode === 'duplicate';
  const isDraft = useIsFlowDraft(control);

  return isDuplicatedLink || (isDuplicatedLink && isDraft);
}
