import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useWatch } from 'react-hook-form';
import { useParams } from 'react-router';

import { Box, Collapsible, tokens, Icon, Tooltip } from '@unitoio/mosaic';

import { getFeatureFlagValue, getField } from 'reducers';
import * as featureTypes from '~/consts/features';
import * as fieldTypes from '~/consts/fields';
import * as trackingTypes from '~/consts/tracking';
import { useGetContainers } from '~/hooks/useGetContainers';
import { useGetItemTypes } from '~/hooks/useGetItemTypes';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { useLocalStorage } from '~/hooks/useLocalStorage';
import { getSortedProviderNames } from '~/utils/getSortedProviderNames';

import * as formUtils from '../utils/form';
import { flowBuilderTypes } from '../consts';
import { FieldAssociationItemInputs } from './FieldAssociationItemInputs';
import { useGetCapabilitiesV3 } from '../hooks/useGetCapabilitiesV3';
import { FieldMappingPlaceholder } from './mapping/FieldMappingPlaceholder';

const HAS_SEEN_VALUE_MAPPING_EDUCATIONAL_TOOLTIP = 'hasSeenValueMappingEducationalTooltip';

const GrowingBox = styled(Box)`
  flex-grow: 1;
  display: flex;
`;

const ClickableIcon = styled(Icon)`
  pointer-events: none;
`;

const FullWidth = styled(Box)`
  width: 100%;
`;

const TrashContainer = styled(Box)`
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
  position: absolute;
  right: 3.5rem;
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
  opacity: ${({ $disabled }) => ($disabled ? '0.3' : '1')};
`;

const PaddedCollapsible = styled(Collapsible)`
  padding: 0 ${tokens.spacing.s3};
`;

const HoverableBox = styled(Box)`
  &:hover {
    border-color: ${(props) =>
      props.$isFirstFieldAssociation ? 'transparent' : tokens.colors.content.secondary.default};
  }

  &:not(:hover) ${TrashContainer} {
    visibility: hidden;
  }
`;

const renderFieldAssociationItem = ({
  fieldIdA,
  fieldKindA,
  fieldIdB,
  fieldKindB,
  target,
  index,
  onDelete,
  isEntityAlreadyMapped,
  isCustomFieldLimitReached,
  isFieldMappingLimitReached,
  isFirstFieldAssociation,
  containerIdA,
  containerIdB,
  itemTypeA,
  itemTypeB,
  providerIdentityIdA,
  providerIdentityIdB,
  providerNameA,
  providerNameB,
  disabledDelete,
  isHeader = false,
  directionDisabled,
  fieldAssociations,
  disabled,
}) => (
  <GrowingBox
    fullWidth
    alignItems="center"
    p={[0, tokens.spacing.s5, 0, isHeader ? tokens.spacing.s3 : tokens.spacing.s4]}
  >
    <FullWidth p={[0, isHeader ? tokens.spacing.s3 : tokens.spacing.s4, 0, 0]}>
      <FieldAssociationItemInputs
        fieldAssociation={{
          A: { field: fieldIdA, kind: fieldKindA },
          B: { field: fieldIdB, kind: fieldKindB },
          target,
        }}
        fieldAssociations={fieldAssociations}
        index={index}
        isEntityAlreadyMapped={isEntityAlreadyMapped}
        isCustomFieldLimitReached={isCustomFieldLimitReached}
        isFieldMappingLimitReached={isFieldMappingLimitReached}
        containerIdA={containerIdA}
        containerIdB={containerIdB}
        itemTypeA={itemTypeA}
        itemTypeB={itemTypeB}
        providerIdentityIdA={providerIdentityIdA}
        providerIdentityIdB={providerIdentityIdB}
        providerNameA={providerNameA}
        providerNameB={providerNameB}
        directionDisabled={directionDisabled}
        disabled={disabled}
      />
    </FullWidth>
    {!isFirstFieldAssociation && (
      <TrashContainer
        $disabled={disabledDelete}
        alignItems="center"
        justifyContent="center"
        onClick={onDelete}
        aria-label="Delete fields association"
      >
        <ClickableIcon kind={Icon.KINDS.SOLID} name="trash" />
      </TrashContainer>
    )}
  </GrowingBox>
);

// TODO: Remove specificity of comments.public once we support filtering on fields pcdItems
function getIsPublicCommentAvailable(fieldA, fieldB) {
  if (
    !fieldA.get('semantic', '')?.startsWith(fieldTypes.SEMANTIC.COMMENTS) &&
    !fieldB.get('semantic', '')?.startsWith(fieldTypes.SEMANTIC.COMMENTS)
  ) {
    return false;
  }

  const hasPublicCommentA = fieldA.get('semantic') === fieldTypes.SEMANTIC.COMMENTS_PUBLIC;
  const hasPublicCommentB = fieldB.get('semantic') === fieldTypes.SEMANTIC.COMMENTS_PUBLIC;
  return hasPublicCommentA || hasPublicCommentB;
}

function getIsStreamAttachmentsAvailable(fieldA, fieldB) {
  if (fieldA.get('type') !== fieldTypes.TYPES.ITEM && fieldB.get('type') !== fieldTypes.TYPES.ITEM) {
    return false;
  }

  // FIXME: Stream attachments is only supported in description footer for now.
  // As soon as we support it for string fields, we can remove this and check on string and description footer.
  if (fieldA.getIn(['itemType', 'canStreamBinary'], false)) {
    return fieldB.get('fieldId') === fieldTypes.DESCRIPTION_FOOTER;
  }

  return (
    fieldB.getIn(['itemType', 'canStreamBinary'], false) && fieldA.get('fieldId') === fieldTypes.DESCRIPTION_FOOTER
  );
}

function useFieldMappingStates({ index, providerIdentityIds, providerNames }) {
  const { linkId } = useParams();
  const [, , capabilitiesV2A, capabilitiesV2B] = useGetCapabilitiesV3(linkId);
  const [itemTypeA, itemTypeB] = useGetItemTypes(linkId);
  const [containerA, containerB] = useGetContainers(linkId);
  const A = useWatch({ name: `associations.${index}.A` });
  const B = useWatch({ name: `associations.${index}.B` });
  const target = useWatch({ name: `associations.${index}.target`, defaultValue: fieldTypes.TARGET.BOTH });

  const fieldA = useSelector((state) =>
    getField(state, {
      containerSide: 'A',
      containerId: containerA.get('id'),
      fieldId: A?.field,
      kind: A?.kind,
      providerIdentityId: providerIdentityIds.A,
      providerName: providerNames.A,
      itemType: itemTypeA,
    }),
  );
  const fieldB = useSelector((state) =>
    getField(state, {
      containerSide: 'B',
      containerId: containerB.get('id'),
      fieldId: B?.field,
      kind: B?.kind,
      providerIdentityId: providerIdentityIds.B,
      providerName: providerNames.B,
      itemType: itemTypeB,
    }),
  );

  const isFieldAMappable = formUtils.hasFieldValues(fieldA);
  const isFieldBMappable = formUtils.hasFieldValues(fieldB);

  const isPublicCommentAvailable = getIsPublicCommentAvailable(fieldA, fieldB);
  const isStreamAttachmentAvailable = getIsStreamAttachmentsAvailable(fieldA, fieldB);

  const hasPrefixActivated = useSelector((state) =>
    getFeatureFlagValue(state, featureTypes.FEATURES.FLOW_BUILDER_PREFIX),
  );

  // TODO: Waiting on PCDv3 support for task number prefix - aug 28
  const isPrefixAvailable =
    hasPrefixActivated &&
    (capabilitiesV2A.hasIn(['options', 'taskNumberPrefix']) ||
      capabilitiesV2B.hasIn(['options', 'taskNumberPrefix'])) &&
    [fieldA.get('fieldId'), fieldB.get('fieldId')].includes(fieldTypes.TITLE);

  const isCollapsible =
    (isFieldAMappable && isFieldBMappable) ||
    isStreamAttachmentAvailable ||
    isPublicCommentAvailable ||
    isPrefixAvailable;

  const isItemToStringMapping =
    [fieldA.get('type'), fieldB.get('type')].includes(fieldTypes.TYPES.ITEM) &&
    [fieldA.get('type'), fieldB.get('type')].includes(fieldTypes.TYPES.STRING);

  const isFieldMappingDirectionDisabled =
    !!(fieldA.get('readOnlyOnUpdate') || fieldB.get('readOnlyOnUpdate')) || isItemToStringMapping;

  const hasValueMapping = isFieldAMappable && isFieldBMappable;

  return [
    isCollapsible,
    isFieldMappingDirectionDisabled,
    A?.field,
    B?.field,
    A?.kind,
    B?.kind,
    target,
    hasValueMapping,
    fieldA.getIn(['names', 'singular']),
    fieldB.getIn(['names', 'singular']),
  ];
}

function isNotDeletable({ fieldAssociation, disabledDelete }) {
  if (!fieldAssociation.A?.field || !fieldAssociation.B?.field) {
    return false;
  }

  return disabledDelete;
}

function getExpandIcon(isOpen, onOpenCollapsible, dataTestId) {
  const tooltipContent = 'Click on the cog here to access value mapping or specific options provided for a mapping.';
  const ariaLabel = isOpen ? 'Close collapse' : 'Open collapse';

  return (
    <Tooltip content={tooltipContent} onNext={onOpenCollapsible} onNextLabel="Got it" isEducational>
      <Icon aria-label={ariaLabel} data-testid={`${dataTestId}-educational`} name="cog" kind={Icon.KINDS.SOLID} />
    </Tooltip>
  );
}

export const FieldAssociationItem = ({
  children,
  onDelete,
  hasActiveLinksWithValueMapping = false,
  index,
  isEntityAlreadyMapped,
  isCustomFieldLimitReached,
  isFieldMappingLimitReached,
  isFirstFieldAssociation = false,
  containerIdA,
  containerIdB,
  itemTypeA,
  itemTypeB,
  providerIdentityIdA,
  providerIdentityIdB,
  providerNameA,
  providerNameB,
  disabledDelete,
  fieldAssociation,
  fieldAssociations,
  disabled,
  isFirstFieldAssociationWithValueMapping,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const trackEvent = useTrackEvent();
  const [hasSeenValueMappingEducationalTooltip, setHasSeenValueMappingEducationalTooltip] = useLocalStorage(
    HAS_SEEN_VALUE_MAPPING_EDUCATIONAL_TOOLTIP,
    false,
  );

  const [isCollapsible, directionDisabled, fieldIdA, fieldIdB, fieldKindA, fieldKindB, target] = useFieldMappingStates({
    providerIdentityIds: { A: providerIdentityIdA, B: providerIdentityIdB },
    providerNames: { A: providerNameA, B: providerNameB },
    index,
  });

  const notDeletable = isNotDeletable({ fieldAssociation, disabledDelete });
  const associationLeftPadding = isCollapsible || isFirstFieldAssociation ? tokens.spacing.s3 : tokens.spacing.s6;

  function handleOnToggle(newValue) {
    // only track when the user opens the value mapping, not when they closes it.
    if (newValue) {
      trackEvent(trackingTypes.ACTION, {
        action_name: 'clicked to customize mapping',
        selected_tool_names: getSortedProviderNames(providerNameA, providerNameB),
      });
    }

    setIsOpen(newValue);
    setHasSeenValueMappingEducationalTooltip(true);
  }

  const forceShowTooltip =
    isFirstFieldAssociationWithValueMapping &&
    !hasActiveLinksWithValueMapping &&
    !hasSeenValueMappingEducationalTooltip;
  const expandIcon = forceShowTooltip
    ? getExpandIcon(
        isOpen,
        () => setHasSeenValueMappingEducationalTooltip(true),
        `${fieldAssociation.A?.field}-${fieldAssociation.B?.field}-cog`,
      )
    : undefined;

  return (
    <HoverableBox
      alignItems="center"
      borderRadius={tokens.spacing.s3}
      borderSize={1}
      borderColor={isOpen ? tokens.colors.content.secondary.default : 'transparent'}
      p={[0, tokens.spacing.s3, 0, associationLeftPadding]}
      $isFirstFieldAssociation={isFirstFieldAssociation}
    >
      {isCollapsible && !isFirstFieldAssociation ? (
        <PaddedCollapsible
          dataTestId={`${fieldAssociation.A?.field}-${fieldAssociation.B?.field}-cog`}
          headerOpensOnClick={false}
          expandIconPosition="left"
          expandIcon={expandIcon}
          expandIconLeft="cog"
          expandIconKind={Icon.KINDS.SOLID}
          disabled={disabled}
          isLoading={disabled}
          header={renderFieldAssociationItem({
            fieldIdA,
            fieldKindA,
            fieldIdB,
            fieldKindB,
            target: target ?? fieldAssociation.target,
            fieldAssociations,
            index,
            onDelete,
            isEntityAlreadyMapped,
            isCustomFieldLimitReached,
            isFieldMappingLimitReached,
            isFirstFieldAssociation,
            containerIdA,
            containerIdB,
            itemTypeA,
            itemTypeB,
            providerIdentityIdA,
            providerIdentityIdB,
            providerNameA,
            providerNameB,
            disabledDelete: notDeletable,
            isHeader: true,
            directionDisabled,
            disabled,
          })}
          onToggle={(newValue) => handleOnToggle(newValue)}
        >
          {disabled ? <FieldMappingPlaceholder /> : children}
        </PaddedCollapsible>
      ) : (
        renderFieldAssociationItem({
          fieldAssociations,
          fieldIdA,
          fieldKindA,
          fieldIdB,
          fieldKindB,
          target: target ?? fieldAssociation.target,
          index,
          onDelete,
          isEntityAlreadyMapped,
          isCustomFieldLimitReached,
          isFieldMappingLimitReached,
          isFirstFieldAssociation,
          containerIdA,
          containerIdB,
          itemTypeA,
          itemTypeB,
          providerIdentityIdA,
          providerIdentityIdB,
          providerNameA,
          providerNameB,
          disabledDelete: notDeletable,
          directionDisabled,
          disabled,
        })
      )}
    </HoverableBox>
  );
};

FieldAssociationItem.propTypes = {
  children: PropTypes.node,
  containerIdA: PropTypes.string.isRequired,
  containerIdB: PropTypes.string.isRequired,
  hasActiveLinksWithValueMapping: PropTypes.bool,
  itemTypeA: PropTypes.string.isRequired,
  itemTypeB: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isCustomFieldLimitReached: PropTypes.bool.isRequired,
  isFieldMappingLimitReached: PropTypes.bool.isRequired,
  isEntityAlreadyMapped: PropTypes.func.isRequired,
  isFirstFieldAssociation: PropTypes.bool,
  onDelete: PropTypes.func,
  disabledDelete: PropTypes.bool.isRequired,
  providerIdentityIdA: PropTypes.string.isRequired,
  providerIdentityIdB: PropTypes.string.isRequired,
  providerNameA: PropTypes.string.isRequired,
  providerNameB: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  fieldAssociation: flowBuilderTypes.fieldAssociationPropTypes.isRequired,
  fieldAssociations: PropTypes.arrayOf(flowBuilderTypes.fieldAssociationPropTypes).isRequired,
  isFirstFieldAssociationWithValueMapping: PropTypes.bool.isRequired,
};
