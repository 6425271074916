import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { useDispatch } from 'react-redux';

import { Modal, Typography, TypographyVariants, message } from '@unitoio/mosaic';

import * as linkTypes from '~/consts/link';
import * as linkActions from '~/actions/links';

async function handleDeleteLink({ dispatch, sync, onDeleteSync = () => {}, isDraft }) {
  await dispatch(linkActions.deleteLink(sync.get('_id')));
  onDeleteSync();

  if (sync.get('multisync')) {
    return;
  }

  message.success({
    content: isDraft ? 'Draft deleted' : 'Flow deleted',
    duration: 5,
  });
}

const getModalTitle = (isDraft, syncKind) => {
  if (isDraft) {
    return 'Delete this draft';
  }

  if (syncKind === 'taskSync') {
    return 'Delete this mirror';
  }

  return 'Delete this flow';
};

export function LinkItemDeleteModal({ sync, isOpen, onCancel, onRequestClose, onDeleteSync }) {
  const dispatch = useDispatch();
  const flowName = sync.get('name');
  const isDraft = sync.get('state') === linkTypes.LINK_STATES.DRAFT;
  const syncKind = sync.get('kind');

  return (
    <div data-testid="link-item-delete-modal">
      <Modal
        size="lg"
        confirmLabel={getModalTitle(isDraft, syncKind)}
        isOpen={isOpen}
        onCancel={onCancel}
        onConfirm={() => handleDeleteLink({ dispatch, sync, onDeleteSync, isDraft })}
        onRequestClose={onRequestClose}
        title={`${isDraft ? 'Delete this draft?' : 'Delete this flow?'}`}
        isConfirmActionDestructive
      >
        <Typography variant={TypographyVariants.BODY1}>
          This will permanently delete the {isDraft ? 'draft of' : 'flow'} <b>{flowName}</b> and remove it from your
          workspace, including from any workflows it was added to.
        </Typography>
      </Modal>
    </div>
  );
}

LinkItemDeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDeleteSync: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
  sync: PropTypes.instanceOf(Map).isRequired,
};
