import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { TrackingFunnelContext } from '~/contexts';

import { useGetSelectedToolNames } from '../../../hooks';

const AUTOPOPULATE_MODULE = 'AUTOPOPULATE';

export function AutopopulateContext({ children }) {
  const selectedToolNames = useGetSelectedToolNames();
  const autopopulateContext = useMemo(
    () => ({ name: AUTOPOPULATE_MODULE, sharedProperties: { selected_tool_names: selectedToolNames } }),
    [selectedToolNames],
  );

  return <TrackingFunnelContext.Provider value={autopopulateContext}>{children}</TrackingFunnelContext.Provider>;
}

AutopopulateContext.propTypes = {
  children: PropTypes.node.isRequired,
};
