import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Box, LoadingPlaceholder, tokens } from '@unitoio/mosaic';

import { useFetchItemInfo } from '~/hooks/useFetchItemInfo';
import { useTrackEvent } from '~/hooks/useTrackEvent';

import { Card as WorkItemCard } from '../../WorkItemStatus/components';
import { ErrorBlock } from './ErrorBlock';

const getFromNow = (dateAt) => {
  if (!dateAt) {
    return 'Never';
  }

  return moment(dateAt).fromNow();
};

export const ItemCardBlock = ({ itemId, itemLastUpdatedAt }) => {
  const trackEvent = useTrackEvent();
  const { isLoading, item, error } = useFetchItemInfo(itemId);

  if (isLoading) {
    return (
      <Box
        borderRadius={tokens.spacing.s4}
        borderSize={1}
        p={[tokens.spacing.s4, tokens.spacing.s5]}
        flexDirection={Box.flexDirection.ROW}
        m={[0, 0, tokens.spacing.s4, 0]}
      >
        <Box m={[0, tokens.spacing.s4, 0, 0]}>
          <LoadingPlaceholder width={tokens.spacing.s7} borderRadius={tokens.spacing.s4} height={tokens.spacing.s7} />
        </Box>
        <Box flexDirection={Box.flexDirection.COLUMN}>
          <Box m={[0, 0, tokens.spacing.s3, 0]}>
            <LoadingPlaceholder width="22" borderRadius={tokens.spacing.s4} height={tokens.spacing.s4} />
          </Box>
          <Box>
            <LoadingPlaceholder width="11" borderRadius={tokens.spacing.s4} height={tokens.spacing.s4} />
          </Box>
        </Box>
      </Box>
    );
  }

  if (error) {
    return (
      <Box m={[0, 0, tokens.spacing.s4, 0]}>
        <ErrorBlock />
      </Box>
    );
  }

  let itemContainerSide;
  if (item.flow) {
    itemContainerSide = item.flow.A.container.id === item.containerId ? 'A' : 'B';
  }

  return (
    <WorkItemCard
      providerNameA={item.provider?.name}
      primaryTitle={item.displayName}
      secondaryTitle={
        item.flow ? `Last updated: ${getFromNow(itemLastUpdatedAt)}` : 'This item is no longer synced in an active flow'
      }
      href={item.url}
      iconDataA={
        item.flow && {
          url: item.flow[itemContainerSide].container.url,
          tooltipContent: item.flow[itemContainerSide].container.displayName,
        }
      }
      trackEvent={trackEvent}
    />
  );
};

ItemCardBlock.propTypes = {
  itemId: PropTypes.string.isRequired,
  itemLastUpdatedAt: PropTypes.number.isRequired,
};
