import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';

import { Icon } from '@unitoio/mosaic';

import { getSyncActivityStatus } from '~/utils/getSyncActivityStatus';
import * as routes from '~/consts/routes';
import * as linkTypes from '~/consts/link';
import { color, fontSize } from 'theme';
import { useGetSyncStatusActivityAndHealth } from '~/hooks/useGetSyncStatusActivityAndHealth';
import { Href } from '~/components/Href/Href';

const StatusBlock = styled.div`
  display: flex;
  align-items: center;
`;

const StatusCopy = styled.span`
  display: inline-block;
  margin-left: 0.5rem;
  min-width: 10rem;
`;

const Fade = styled.div`
  font-size: ${fontSize.small};
  transition: 0.5s;
  opacity: ${(props) => (props.$state === 'entered' ? 1 : 0)};
  transform: ${(props) => (props.$state === 'entered' ? 'translateY(0)' : 'translateY(100%)')};
`;

const FadeTransition = ({ children, ...rest }) => (
  <Transition {...rest} in>
    {(state) => <Fade $state={state}>{children}</Fade>}
  </Transition>
);

FadeTransition.propTypes = {
  children: PropTypes.node.isRequired,
};

export function LinkStatus({ syncId, editSyncUrl = null, isSuspended }) {
  const [activity, health, linkState] = useGetSyncStatusActivityAndHealth(syncId);
  const syncStatusKey = getSyncActivityStatus(linkState, activity, health, isSuspended);
  const {
    iconName = 'times',
    iconColor = `${color.content.neutral.border}`,
    copy = 'Status not available',
    spin,
  } = linkTypes.LINK_STATUS_VALUES[syncStatusKey] || {};

  const syncActivityUrl = editSyncUrl || `${routes.ABSOLUTE_PATHS.FLOW_BUILDER_EDIT}/${syncId}`;

  const statusBlock = () => (
    <StatusBlock>
      <Icon name={iconName} color={iconColor} spin={spin} />
      <StatusCopy>{copy}</StatusCopy>
    </StatusBlock>
  );

  return (
    <FadeTransition key={`${health}-${activity}`} timeout={500} appear mountOnEnter unmountOnExit>
      <Href to={syncActivityUrl} linkStyle="subtleLink">
        {statusBlock()}
      </Href>
    </FadeTransition>
  );
}

LinkStatus.propTypes = {
  syncId: PropTypes.string.isRequired,
  editSyncUrl: PropTypes.string,
  isSuspended: PropTypes.bool.isRequired,
};
