import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { useFormContext, useFormState } from 'react-hook-form';

import { Box, Icon, ProviderIcon, tokens, Typography } from '@unitoio/mosaic';

import { getLinkById } from 'reducers';
import * as linkTypes from '~/consts/link';
import * as trackingTypes from '~/consts/tracking';
import * as fieldTypes from '~/consts/fields';
import * as routes from '~/consts/routes';
import { useGetItemTypes } from '~/hooks/useGetItemTypes';

import { getIsWorkItemsPageCompleted } from '../../utils/form';
import { useGetProvidersCanCreateWorkItem } from '../../hooks/useGetProvidersCanCreateWorkItem';
import { GuideStepTitleWithTime } from './GuideStepTitleWithTime';
import { GuideStep } from './GuideStep';
import FlowDirectionIcon from '../../images/steps_icons/flow_direction.svg';

const DIRECTIONS = {
  both: {
    text: 'Two-way sync selected',
    icon: 'exchange',
  },
  A: {
    text: 'One-way sync selected',
    icon: 'long-arrow-left',
  },
  B: {
    text: 'One-way sync selected',
    icon: 'long-arrow-right',
  },
};

export const GuideStepFlowDirection = ({ linkState }) => {
  const { linkId } = useParams();
  const { errors } = useFormState();
  const currentLink = useSelector((state) => getLinkById(state, linkId));
  const { watch } = useFormContext();
  const linkKind = useSelector((state) => getLinkById(state, linkId)).get('kind');
  const isLiteGuide = linkTypes.GUIDE_VARIANTS[linkKind] === linkTypes.GUIDE_VARIANT_TYPE.LITE;
  const syncDirection = watch('syncDirection');

  const isWorkItemStepCompleted = getIsWorkItemsPageCompleted(currentLink);
  const providerIdentityAError = errors[trackingTypes.MODULE.TOOL_SELECTION]?.A?.providerIdentityId;
  const providerIdentityBError = errors[trackingTypes.MODULE.TOOL_SELECTION]?.B?.providerIdentityId;
  const containerAError = errors[trackingTypes.MODULE.TOOL_SELECTION]?.A?.containerId;
  const containerBError = errors[trackingTypes.MODULE.TOOL_SELECTION]?.B?.containerId;
  const hasContainerErrors = providerIdentityAError || providerIdentityBError || containerAError || containerBError;
  const providerNameA = currentLink.getIn(['A', 'providerName']);
  const providerNameB = currentLink.getIn(['B', 'providerName']);
  const containerIdA = currentLink.getIn(['A', 'container', 'id']);
  const containerIdB = currentLink.getIn(['B', 'container', 'id']);
  const [itemTypeA, itemTypeB] = useGetItemTypes(linkId);

  const { target: canCreateTarget } = useGetProvidersCanCreateWorkItem({
    containerIdA,
    containerIdB,
    providerNameA,
    providerNameB,
    itemTypeA,
    itemTypeB,
  });

  const isStepSkipped = canCreateTarget !== fieldTypes.TARGET.BOTH;

  const progress = linkState === linkTypes.LINK_STATES.DRAFT ? 'done' : 'edit';
  const isStepLocked = isLiteGuide && progress === 'edit';
  const status = isStepLocked || isStepSkipped ? 'locked' : progress;

  if (hasContainerErrors) {
    return syncDirection ? (
      <GuideStep status="inaccessible" statusLabel="Flow direction status">
        <ProviderIcon name={providerNameA} />
        <Box p={[0, tokens.spacing.s3, 0, tokens.spacing.s3]}>
          <Icon name={DIRECTIONS[syncDirection].icon} />
        </Box>
        <ProviderIcon name={providerNameB} />
        <Box p={[0, 0, 0, tokens.spacing.s3]}>
          <Typography variant="body1">{DIRECTIONS[syncDirection].text}</Typography>
        </Box>
      </GuideStep>
    ) : (
      <GuideStep status="inaccessible" statusLabel="Flow direction status">
        <GuideStepTitleWithTime title="2. Set your flow’s direction" icon={FlowDirectionIcon} time={1} />
      </GuideStep>
    );
  }

  if (syncDirection && isWorkItemStepCompleted) {
    return (
      <GuideStep
        status={status}
        actionSlug={routes.FLOW_BUILDER_PAGES.FLOW_DIRECTION}
        actionLabel="Edit direction"
        statusLabel="Flow direction status"
      >
        <ProviderIcon name={providerNameA} />
        <Box p={[0, tokens.spacing.s3, 0, tokens.spacing.s3]}>
          <Icon name={DIRECTIONS[syncDirection].icon} />
        </Box>
        <ProviderIcon name={providerNameB} />
        <Box p={[0, 0, 0, tokens.spacing.s3]}>
          <Typography
            variant="body1"
            color={status === 'locked' ? tokens.colors.content.neutral.n20 : tokens.colors.content.neutral.n40}
          >
            {DIRECTIONS[syncDirection].text}
          </Typography>
        </Box>
      </GuideStep>
    );
  }

  return (
    <GuideStep
      status={isWorkItemStepCompleted ? 'current' : 'default'}
      actionLabel={isWorkItemStepCompleted ? 'Continue' : undefined}
      actionSlug={isWorkItemStepCompleted ? routes.FLOW_BUILDER_PAGES.FLOW_DIRECTION : undefined}
      statusLabel="Flow direction status"
    >
      <GuideStepTitleWithTime title="2. Set your flow’s direction" icon={FlowDirectionIcon} time={1} />
    </GuideStep>
  );
};

GuideStepFlowDirection.propTypes = {
  linkState: PropTypes.oneOf(Object.values(linkTypes.LINK_STATES)).isRequired,
};
