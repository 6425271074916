import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useTrackEvent } from '~/hooks/useTrackEvent';

import styled from 'styled-components';

import { tokens } from '@unitoio/mosaic';

import * as featureTypes from '~/consts/features';
import * as routes from '~/consts/routes';
import * as trackingTypes from '~/consts/tracking';
import {
  getFeatureFlagValue,
  getMaxUsageFeatureToDisplay,
  getSelectedOrganizationId,
  isOrganizationOverFeatureLimit,
} from 'reducers';
import { useGetOrganizationUsage } from '~/hooks/useGetOrganizationUsage';
import { Button } from '~/components/Button/Button';
import { Box } from '~/components/Box/Box';
import { Href } from '~/components/Href/Href';

const Banner = styled((props) => <Box {...props} />)`
  background-color: ${tokens.colors.content.warning.default};
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 40px;
`;

export const OverPlanLimitBanner = () => {
  const currentOrganizationId = useSelector((state) => getSelectedOrganizationId(state));
  const { isUsageLoading, usageError } = useGetOrganizationUsage(currentOrganizationId);
  const featureToShow = useSelector((state) => getMaxUsageFeatureToDisplay(state, currentOrganizationId));
  const isOverPlanLimit = useSelector((state) =>
    isOrganizationOverFeatureLimit(state, currentOrganizationId, featureToShow.get('id')),
  );
  const showBannerFeatureFlag = useSelector((state) =>
    getFeatureFlagValue(state, 'show-over-limit-banner', currentOrganizationId),
  );
  const calculationDetailsUrl =
    featureToShow.get('id') === featureTypes.FEATURES.MAX_USERS
      ? 'https://guide.unito.io/what-are-active-users'
      : 'https://guide.unito.io/what-are-items-in-sync';

  const showBanner = showBannerFeatureFlag && isOverPlanLimit;

  const trackEvent = useTrackEvent({
    banner_type: trackingTypes.BANNER_EVENTS.TYPES.OVER_PLAN_LIMIT,
    feature_over_limit: featureToShow.get('id'),
  });

  useEffect(() => {
    if (showBanner) {
      trackEvent(trackingTypes.BANNER_EVENTS.START, {});
    }
  }, [showBanner, trackEvent]);

  if (isUsageLoading || usageError) {
    return null;
  }

  return (
    <>
      {showBanner && (
        <Banner $p={[0.5, 0]}>
          Heads up! You’re
          <Box $m={[0, 0.25]}>
            <b>over the number of {featureToShow.get('displayName')}</b>
          </Box>
          allowed on your plan.
          <Box $m={[0, 0, 0, 0.25]}>
            <Href underline data-color={tokens.colors.content.neutral.n40} href={calculationDetailsUrl} target="_blank">
              Find out more about how this number is calculated.
            </Href>
          </Box>
          <Box $m={[0, 1]}>
            <Button
              type="href"
              to={`${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/${currentOrganizationId}/pricing`}
              size="sm"
              transparent
              reverse
              color={tokens.colors.content.neutral.n40}
              onClick={() =>
                trackEvent(trackingTypes.BANNER_EVENTS.ACTION, {
                  action_name: trackingTypes.BANNER_EVENTS.ACTIONS.UPGRADE_PLAN,
                })
              }
            >
              Upgrade your plan
            </Button>
          </Box>
        </Banner>
      )}
    </>
  );
};
