import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Box, Button, Icon, Link, Typography, tokens } from '@unitoio/mosaic';

import { getFirstActivityLogsByItemId, getLinkProviderNameBySide } from 'reducers';
import { capitalize } from '~/utils/capitalize';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import * as trackingTypes from '~/consts/tracking';

const StyledLink = styled(Link)`
  color: ${tokens.colors.global.primary.dark} !important;
`;

const StyledIcon = styled(Icon)`
  margin-right: ${tokens.spacing.s4};
`;

const LinkWrapper = styled(Link)`
  color: ${tokens.colors.global.primary.dark} !important;
  margin: 0;
  text-decoration: none !important;
`;

const Resource = ({ icon, label, url, trackEvent }) => (
  <LinkWrapper
    href={url}
    target="_blank"
    onClick={() =>
      trackEvent(trackingTypes.ACTION, {
        action_name: trackingTypes.ACTIVITY_LOG.ACTIONS.CLICKED_ARTICLE,
        article_url: url,
      })
    }
  >
    <Box
      m={[0, 0, tokens.spacing.s4, 0]}
      borderRadius={tokens.spacing.s3}
      borderSize={1}
      p={[tokens.spacing.s1, tokens.spacing.s3, tokens.spacing.s1, tokens.spacing.s4]}
      alignItems={Box.alignItems.CENTER}
    >
      <Box m={[0, 0, 0, 0]}>
        <Typography>
          <StyledIcon name={icon} kind={Icon.KINDS.REGULAR} />
          {label}
        </Typography>
      </Box>
      <Box m={[0, 0, 0, 'auto']}>
        <StyledLink target="_blank" href={url}>
          <Button variant={Button.variants.SUBTLE} startIcon="external-link">
            Open
          </Button>
        </StyledLink>
      </Box>
    </Box>
  </LinkWrapper>
);

Resource.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  trackEvent: PropTypes.func.isRequired,
};

export const ResourceBlock = ({ itemId }) => {
  // Activity log will always be defined in this block because to render a RessourceBlock, it requires an Activity log.
  const firstActivityLog = useSelector((state) => getFirstActivityLogsByItemId(state, itemId));

  const linkId = firstActivityLog.get('linkId');

  const providerNameA = useSelector((state) => getLinkProviderNameBySide(state, { containerSide: 'A', linkId }));
  const providerNameB = useSelector((state) => getLinkProviderNameBySide(state, { containerSide: 'B', linkId }));

  const sourceConnectorName = firstActivityLog.get('sourceConnectorName');
  const targetConnectorName = firstActivityLog.get('targetConnectorName');

  const sourceDisplayName = providerNameA ?? sourceConnectorName;
  const targetDisplayName = providerNameB ?? targetConnectorName;

  const trackEvent = useTrackEvent();

  return (
    <>
      <Box m={[0, 0, tokens.spacing.s4, 0]}>
        <Typography variant={Typography.variants.H4}>Resources</Typography>
      </Box>
      <Resource
        icon="book"
        label="Common error messages and how to fix them"
        url="https://guide.unito.io/common-error-messages"
        trackEvent={trackEvent}
      />
      <Resource
        icon="browser"
        label={`Learn more about syncing ${capitalize(sourceDisplayName)} with ${capitalize(targetDisplayName)}`}
        url={`https://unito.io/resources/?page=0&toolFilter=${sourceDisplayName},${targetDisplayName}`}
        trackEvent={trackEvent}
      />
      <Resource
        icon="browser"
        label="Visit Unito's help center for more information"
        url="https://guide.unito.io/"
        trackEvent={trackEvent}
      />
    </>
  );
};

ResourceBlock.propTypes = {
  itemId: PropTypes.string.isRequired,
};
