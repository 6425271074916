import React from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { NewAlert as Alert, NewAlertLevel } from '@unitoio/mosaic';

import { getProviderVisibleFields, getProviderByName } from 'reducers';
import { useGetContainers } from '~/hooks/useGetContainers';
import { useGetItemTypes } from '~/hooks/useGetItemTypes';
import { ProviderTermsByName } from '~/components/ProviderTerms/ProviderTermsByName';

import { useGetIncompleteItemFieldAssociation } from '../hooks/useGetIncompleteItemFieldAssociation';

const ProviderDisplayName = ({ providerName }) =>
  useSelector((state) => getProviderByName(state, providerName).get('displayName'));

const ProviderFieldTerm = ({ fields, fieldId, plurality = 'singular' }) => {
  const fieldDisplayName = fields?.getIn([fieldId, 'names', plurality]);

  if (!fieldDisplayName) {
    return fieldId;
  }

  return fieldDisplayName;
};

export function IncompleteItemFieldAssociationsAlert() {
  const { linkId } = useParams();
  const { watch } = useFormContext();

  const providerNameA = watch('A.providerName');
  const providerNameB = watch('B.providerName');
  const providerIdA = useSelector((state) => getProviderByName(state, providerNameA)).get('_id');
  const providerIdB = useSelector((state) => getProviderByName(state, providerNameB)).get('_id');

  const [containerA, containerB] = useGetContainers(linkId);
  const [itemTypeA, itemTypeB] = useGetItemTypes(linkId);
  const fieldsA = useSelector((state) =>
    getProviderVisibleFields(state, providerIdA, itemTypeA, containerA?.get('id')),
  );
  const fieldsB = useSelector((state) =>
    getProviderVisibleFields(state, providerIdB, itemTypeB, containerB?.get('id')),
  );

  const [missingItemFieldAssociationA, missingItemFieldAssociationB] = useGetIncompleteItemFieldAssociation(false);

  if (!missingItemFieldAssociationA && !missingItemFieldAssociationB) {
    return null;
  }

  return (
    <>
      {missingItemFieldAssociationA && (
        <Alert
          level={NewAlertLevel.WARNING}
          message={
            <>
              A <ProviderDisplayName providerName={providerNameB} /> field containing the same values as{' '}
              <ProviderDisplayName providerName={providerNameA} />{' '}
              <ProviderFieldTerm
                plurality="singular"
                fieldId={missingItemFieldAssociationA}
                providerName={providerNameA}
                fields={fieldsA}
              />{' '}
              names is required to associate incoming{' '}
              <ProviderTermsByName
                plurality="plural"
                termKey="tasks"
                providerNameA={providerNameB}
                pcdv3
                itemTypeA={itemTypeB}
              />{' '}
              to the correct corresponding{' '}
              <ProviderFieldTerm
                plurality="singular"
                fieldId={missingItemFieldAssociationA}
                providerName={providerNameA}
              />
              .
            </>
          }
        />
      )}

      {missingItemFieldAssociationB && (
        <Alert
          level={NewAlertLevel.WARNING}
          message={
            <>
              A <ProviderDisplayName providerName={providerNameA} /> field containing the same values as{' '}
              <ProviderDisplayName providerName={providerNameB} />{' '}
              <ProviderFieldTerm
                plurality="singular"
                fieldId={missingItemFieldAssociationB}
                providerName={providerNameB}
                fields={fieldsB}
              />{' '}
              names is required to associate incoming{' '}
              <ProviderTermsByName
                plurality="plural"
                termKey="tasks"
                providerNameA={providerNameA}
                pcdv3
                itemTypeA={itemTypeA}
              />{' '}
              to the correct corresponding{' '}
              <ProviderFieldTerm
                plurality="singular"
                fieldId={missingItemFieldAssociationB}
                providerName={providerNameB}
              />
              .
            </>
          }
        />
      )}
    </>
  );
}
